import React from 'react';
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';

import PageHelmet from '../../components/shared/PageHelmet';


const Home = () => {
    return (
        
        <>
            <PageHelmet pageTitle="الإتحاد الرسمي لسكان منطقة بيت الوطن بالقاهرة الجديدة" />
            <div className='overFlow'>
                
            <HomeOneHeader/>
            
            <HomeHeroSlider/>

            </div>


            
            
            {/* <HomeAbout/>
            <HomeCapabilities/>
            <HomeServices/>
            <HomeCounterArea/>
            <HomeTeams/>
            <CommonCtaArea/>
            <HomePricing/>
            <HomeBrands/>
            <HomeContact/> */}
            {/* <Footer/> */}
        </>
    );
};

export default Home;