import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  InputGroup,
  FormControl,
  Card,
  Col,
  Row,
  Form,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import axios from "axios";
import {
  FaWhatsapp,
  FaPhone,
  FaEdit,
  FaFilePdf,
  FaFileExcel,
  FaCopy,
  FaTrash,
  FaPlus,
  FaEye,
  FaSearchPlus,
  FaSearchMinus,
  FaUser,
  FaAlignLeft,
  FaCity,
  FaBuilding,
  FaFont,
  FaHashtag,
  FaSortUp,
  FaSortDown,
  FaEnvelope,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "react-js-pagination";
import { Document, Page } from 'react-pdf';  // لعرض ملفات PDF
import Zoom from 'react-medium-image-zoom';   // لعرض الصور مع خاصية التكبير
import { Table } from "react-bootstrap";
import 'react-medium-image-zoom/dist/styles.css';

const AdminDashboard = () => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [letters, setLetters] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [areaFilter, setAreaFilter] = useState("all");
  const [neighborhoodFilter, setNeighborhoodFilter] = useState("all");
  const [letterFilter, setLetterFilter] = useState("all");
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeTab, setActiveTab] = useState("personalInfo");
  const [formData, setFormData] = useState({});
  const [editingTab, setEditingTab] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    phone1: "",
    phone2: "",
    whatsapp1: "",
    whatsapp2: "",
  });
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage] = useState(5);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractFileUrl, setContractFileUrl] = useState("");
  const [showContractModal, setShowContractModal] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [pdfPage, setPdfPage] = useState(1);
  const [numPdfPages, setNumPdfPages] = useState(null);
  const [buildingNumberFilter, setBuildingNumberFilter] = useState("");
  const [buildings, setBuildings] = useState([]);  // لتخزين أرقام العمارات
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortField, setSortField] = useState(""); // حقل الترتيب المختار
const [sortOrder, setSortOrder] = useState("asc"); // ترتيب تصاعدي أو تنازلي
const [searchTerm, setSearchTerm] = useState('');
const [floorFilter, setFloorFilter] = useState("all");
const [unitNumberFilter, setUnitNumberFilter] = useState("");



  const handleBuildingChange = (e) => {
    const buildingNumber = e.target.value;
    setBuildingNumberFilter(buildingNumber);
    filterMembers(); // تصفية الأعضاء بناءً على العمارة
  };

  useEffect(() => {
    filterMembers();
  }, [statusFilter, areaFilter, neighborhoodFilter, letterFilter, buildingNumberFilter, members]);

  const baseURL = "https://b-watan.net/"; // رابط الـ base للملفات المرفقة

  useEffect(() => {
    fetchMembers();
    fetchAreas();
  }, []);

  useEffect(() => {
    filterMembers();
  }, [statusFilter, areaFilter, neighborhoodFilter, letterFilter, members]);

  
  const fetchMembers = async () => {
    setLoading(true);
    try {
      // جلب الأعضاء
      const response = await axios.get(
        "https://b-watan.net/members.php?endpoint=getAllMembersWithNotes"
      );
      const membersArray = Array.isArray(response.data)
        ? response.data
        : Object.values(response.data);

      // تعيين الأعضاء في حالة state
      setMembers(membersArray);
      setFilteredMembers(membersArray);

      // جلب أسماء المناطق والأحياء والحروف بعد جلب الأعضاء
      if (membersArray.length > 0) {
        await fetchAreas();  // جلب أسماء المناطق
        await fetchNeighborhoods(membersArray[0].info.area_id);  // جلب أسماء الأحياء
        await fetchLetters(membersArray[0].info.neighborhood_id);  // جلب أسماء الحروف
      }

      setTotalItemsCount(membersArray.length);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
    setLoading(false);
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${baseURL}members.php?endpoint=getAreas`);
      setAreas(response.data);
    } catch (error) {
      console.error("Error fetching areas:", error);
    }
  };

  const fetchNeighborhoods = async (area_id) => {
    try {
      const response = await axios.get(
        `${baseURL}members.php?endpoint=getNeighborhoods&area_id=${area_id}`
      );
      setNeighborhoods(response.data);
    } catch (error) {
      console.error("Error fetching neighborhoods:", error);
    }
  };

  const fetchLetters = async (neighborhood_id) => {
    try {
      const response = await axios.get(
        `https://b-watan.net/members.php?endpoint=getLetters&neighborhood_id=${neighborhood_id}`
      );
      setLetters(response.data);
      // هنا نفترض أن بيانات الـ API تحتوي على عدد العمارات في كل حرف
      if (response.data && response.data.length > 0) {
        const numberOfBuildings = response.data[0].numberOfBuildings; // نحصل على عدد العمارات
        const buildingsArray = Array.from({ length: numberOfBuildings }, (_, i) => i + 1);  // توليد أرقام العمارات من 1 إلى العدد القادم
        setBuildings(buildingsArray);  // تحديث حالة العمارات
      }
    } catch (error) {
      console.error("Error fetching letters:", error);
    }
  };

  const getAreaName = (areaId) => {
    if (areaId === "all" || areaId === null) {
      return "كل المناطق";
    }
    const area = areas.find((a) => a.id === areaId);
    return area ? area.name : "غير معروف";
  };

  const getNeighborhoodName = (neighborhoodId) => {
    if (neighborhoodId === "all" || neighborhoodId === null) {
      return "كل الأحياء";
    }
    const neighborhood = neighborhoods.find((n) => n.id === neighborhoodId);
    return neighborhood ? neighborhood.name : "غير معروف";
  };

  const getLetterName = (letterId) => {
    if (letterId === "all" || letterId === null) {
      return "كل الحروف";
    }
    const letter = letters.find((l) => l.id === letterId);
    return letter ? letter.letter : "غير معروف";
  };

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    setActivePage(1);
  };
  
  

  const fetchBuildings = async (letter_id) => {
    try {
      const response = await axios.get(
        `https://b-watan.net/members.php?endpoint=getBuildings&letter_id=${letter_id}`
      );
      const totalBuildings = response.data.total_buildings;
      if (totalBuildings > 0) {
        const buildingsArray = Array.from({ length: totalBuildings }, (_, i) => i + 1);
        setBuildings(buildingsArray); // حفظ العمارات في state
      } else {
        setBuildings([]); // إذا لم يكن هناك أي عمارات، نضع المصفوفة فارغة
      }
    } catch (error) {
      console.error("Error fetching buildings:", error);
    }
  };

  const handleStatusChange = (member) => {
    // تأكد من أن الـ ID الخاص بالعضو متوفر
    if (!member.id) {
      toast.error("لم يتم العثور على معرف العضو.");
      return;
    }

    setSelectedMember(member);
    setStatus(member.info.status || ""); // تحميل حالة العضو
    setReason(member.info.status_reason || ""); // تحميل سبب الحالة
    setShowEditModal(true); // فتح المودال
  };

  const handleSaveStatus = async () => {
    if (!selectedMember || !status) {
      toast.error("يرجى اختيار الحالة أو التأكد من تحديد العضو.");
      return;
    }

    try {
      
      const response = await axios.put(
        `https://b-watan.net/members.php?endpoint=updateStatus`,
        {
          phone_number: selectedMember.info.phone_number, // رقم التليفون الخاص بالعضو
          status: status,  // الحالة التي تم اختيارها
          status_reason: reason,  // سبب تغيير الحالة
        }
      );

      if (response.data.success) {
        toast.success("تم تحديث الحالة بنجاح!");
        setShowEditModal(false);
        fetchMembers();  // لتحديث قائمة الأعضاء بعد التغيير
      } else {
        toast.error("حدث خطأ أثناء تحديث الحالة.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("حدث خطأ أثناء تحديث الحالة. يرجى المحاولة لاحقاً.");
    }
  };

  const handleOpenNotesModal = (member) => {
    setSelectedMember(member);
    setShowNotesModal(true);
  };

  const addNote = async (memberId) => {
    if (newNote.trim() === "") {
      toast.error("لا يمكن إضافة ملاحظة فارغة");
      return;
    }
    try {
      await axios.post(`${baseURL}members.php?endpoint=addNote`, {
        memberId,
        note: newNote,
      });
      setNewNote("");
      toast.success("تم إضافة الملاحظة بنجاح");
      fetchMembers();
    } catch (error) {
      toast.error("حدث خطأ أثناء إضافة الملاحظة");
    }
  };

  const deleteNote = async (noteId) => {
    const confirmDelete = window.confirm("هل أنت متأكد من حذف هذه الملاحظة؟");
    if (confirmDelete) {
      try {
        await axios.delete(
          `${baseURL}members.php?endpoint=deleteNote&note_id=${noteId}`
        );
        toast.success("تم حذف الملاحظة بنجاح");
        fetchMembers();
      } catch (error) {
        toast.error("حدث خطأ أثناء حذف الملاحظة");
      }
    }
  };

  const cardBgColor = (status) => {
    switch (status) {
      case "تم التسجيل":
        return "#d4edda"; // لون أخضر فاتح
      case "مرفوض":
        return "#f8d7da"; // لون أحمر فاتح
      case "تحت المراجعة":
        return "#fff3cd"; // لون أصفر فاتح
      case "معلق":
        return "#f1f1f1"; // لون رمادي فاتح
      default:
        return "#f1f1f1"; // لون رمادي افتراضي
    }
  };

  const textColor = (status) => {
    switch (status) {
      case "تم التسجيل":
        return "#155724"; // لون أخضر داكن
      case "مرفوض":
        return "#721c24"; // لون أحمر داكن
      case "تحت المراجعة":
        return "#856404"; // لون أصفر داكن
      case "معلق":
        return "#343a40"; // لون داكن افتراضي
      default:
        return "#343a40";
    }
  };

  const exportData = (format) => {

  };

  const handleContactModal = (member) => {
    setContactInfo({
      phone1: member.info.phone_number,
      phone2: member.info.secondary_phone,
      whatsapp1: member.info.whatsapp_number,
      whatsapp2: member.info.secondary_whatsapp,
    });
    setSelectedMember(member);  // تعيين العضو المختار
    setShowContactModal(true);  // فتح المودال
  };

  const handleCopyPhoneNumber = (phoneNumber, memberName) => {
    navigator.clipboard.writeText(phoneNumber);
    toast.success(`تم نسخ رقم الهاتف الخاص بـ ${memberName}!`);
  };

  const handleCopyWhatsAppNumber = (whatsAppNumber, memberName) => {
    navigator.clipboard.writeText(whatsAppNumber);
    toast.success(`تم نسخ رقم الواتس آب الخاص بـ ${memberName}!`);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const filterMembers = () => {
    let filtered = [...members]; // نبدأ بجميع الأعضاء
  
    // **البحث الشامل بالشبه في جميع الحقول**
    if (searchTerm.trim() !== "") {
      const searchValueLower = searchTerm.trim().toLowerCase();
  
      filtered = filtered.filter((member) => {
        // تحويل جميع القيم إلى lowercase للمطابقة
        const fullName = member.info.full_name_ar?.toLowerCase() || ""; // الاسم الكامل بالعربية
        const phoneNumber = member.info.phone_number?.toString() || ""; // رقم التليفون
        const whatsappNumber = member.info.whatsapp_number?.toString() || ""; // رقم الواتساب
        const email = member.info.email?.toLowerCase() || ""; // البريد الإلكتروني
        const companyName = member.info.developer_company_name?.toLowerCase() || ""; // اسم الشركة
        const buildingNumber = member.info.building_number?.toString() || ""; // رقم العمارة
        const floorNumber = member.info.floor_number?.toString() || ""; // رقم الطابق
        const unitNumber = member.info.unit_number?.toString() || ""; // رقم الشقة
        const status = member.info.status?.toLowerCase() || ""; // الحالة
        const statusReason = member.info.status_reason?.toLowerCase() || ""; // سبب الحالة
        const companyRating = member.info.developer_rating?.toString() || ""; // تقييم الشركة
        const buildQualityRating = member.info.build_quality_rating?.toString() || ""; // تقييم جودة البناء
  
        // **تقسيم الاسم** للبحث بالشبه، بحيث يمكن البحث بجزء من الاسم
        const fullNameParts = fullName.split(" "); // تقسيم الاسم إلى أجزاء
        const matchesFullName = fullNameParts.some(part => part.startsWith(searchValueLower)); // مطابقة أي جزء من الاسم
  
        // البحث بالشبه في جميع الحقول النصية
        return (
          matchesFullName || // البحث بالاسم بالشبه
          phoneNumber.includes(searchValueLower) || // البحث برقم التليفون بالشبه
          whatsappNumber.includes(searchValueLower) || // البحث برقم الواتساب بالشبه
          email.includes(searchValueLower) || // البحث بالبريد الإلكتروني بالشبه
          companyName.includes(searchValueLower) || // البحث باسم الشركة بالشبه
          buildingNumber.includes(searchValueLower) || // البحث برقم العمارة بالشبه
          floorNumber.includes(searchValueLower) || // البحث بالطابق بالشبه
          unitNumber.includes(searchValueLower) || // البحث برقم الشقة بالشبه
          status.includes(searchValueLower) || // البحث بالحالة بالشبه
          statusReason.includes(searchValueLower) || // البحث بسبب الحالة بالشبه
          companyRating.includes(searchValueLower) || // البحث بتقييم الشركة بالشبه
          buildQualityRating.includes(searchValueLower) // البحث بتقييم جودة البناء بالشبه
        );
      });
    }
  
    // **تصفية الفلاتر** (كما هو)
    // فلترة الحالة
    if (statusFilter !== "all") {
      filtered = filtered.filter((member) => member.info.status === statusFilter);
    }
  
    // فلترة المنطقة
    if (areaFilter !== "all") {
      filtered = filtered.filter((member) => member.info.area_id === areaFilter);
    }
  
    // فلترة الحي
    if (neighborhoodFilter !== "all") {
      filtered = filtered.filter((member) => member.info.neighborhood_id === neighborhoodFilter);
    }
  
    // فلترة الحرف
    if (letterFilter !== "all") {
      filtered = filtered.filter((member) => member.info.letter_id === letterFilter);
    }
  
    // فلترة رقم العمارة
    if (buildingNumberFilter !== "" && buildingNumberFilter !== "all") {
      filtered = filtered.filter((member) => member.info.building_number === buildingNumberFilter);
    }
  
    // فلترة الطابق
    if (floorFilter !== "كل الطوابق" && floorFilter !== "all") {
      filtered = filtered.filter((member) => member.info.floor_number === floorFilter);
    }
  
    // فلترة رقم الشقة
    if (unitNumberFilter.trim() !== "") {
      filtered = filtered.filter((member) => member.info.unit_number?.includes(unitNumberFilter));
    }
  
    // ترتيب الأعضاء بحيث يكون "تحت المراجعة" في المقدمة
    filtered = filtered.sort((a, b) => {
      if (a.info.status === "تحت المراجعة" && b.info.status !== "تحت المراجعة") {
        return -1;
      } else if (a.info.status !== "تحت المراجعة" && b.info.status === "تحت المراجعة") {
        return 1;
      } else {
        return 0;
      }
    });
  
    // **عرض جميع الأعضاء إذا كانت جميع الفلاتر على "all"**
    if (
      searchTerm.trim() === "" && // إذا لم يكن هناك مصطلح بحث
      statusFilter === "all" &&
      areaFilter === "all" &&
      neighborhoodFilter === "all" &&
      letterFilter === "all" &&
      (buildingNumberFilter === "" || buildingNumberFilter === "all") &&
      (floorFilter === "كل الطوابق" || floorFilter === "all") &&
      unitNumberFilter.trim() === ""
    ) {
      setFilteredMembers(members); // عرض جميع الأعضاء بدون فلترة
    } else {
      setFilteredMembers(filtered); // عرض الأعضاء بناءً على الفلاتر
    }
  
    setTotalItemsCount(filtered.length);
    setActivePage(1); // إعادة ضبط الصفحة عند تطبيق الفلاتر
  };
  
  // استدعاء الفلترة عند تغيير الفلاتر
  useEffect(() => {
    filterMembers();
  }, [
    statusFilter,
    areaFilter,
    neighborhoodFilter,
    letterFilter,
    buildingNumberFilter,
    floorFilter,
    unitNumberFilter,
    members,
  ]);
  
  
  // استدعاء الفلترة عند تغيير الفلاتر
  useEffect(() => {
    filterMembers();
  }, [
    statusFilter,
    areaFilter,
    neighborhoodFilter,
    letterFilter,
    buildingNumberFilter,
    floorFilter,
    unitNumberFilter,
    members,
  ]);
  
  
  // استدعاء الفلترة عند تغيير الفلاتر
  useEffect(() => {
    filterMembers();
  }, [
    searchTerm,
    statusFilter,
    areaFilter,
    neighborhoodFilter,
    letterFilter,
    buildingNumberFilter,
    floorFilter,
    unitNumberFilter,
    members,
  ]);
  
  
  
  // استدعاء الفلترة عند تغيير الفلاتر
  useEffect(() => {
    filterMembers();
  }, [
    statusFilter,
    areaFilter,
    neighborhoodFilter,
    letterFilter,
    buildingNumberFilter,
    floorFilter,
    unitNumberFilter,
    members,
  ]);
  
  
  // تنفيذ الفلترة عند تحميل الصفحة وبعد جلب الأعضاء
  useEffect(() => {
    if (members.length > 0) {
      filterMembers(); // تطبيق الفلترة بعد تحميل الأعضاء
    }
  }, [members]);
  
  // إعادة ضبط الفلاتر عند تحميل الصفحة
  useEffect(() => {
    setStatusFilter("all");
    setAreaFilter("all");
    setNeighborhoodFilter("all");
    setLetterFilter("all");
    setBuildingNumberFilter("");
    setFloorFilter("كل الطوابق");
    setUnitNumberFilter("");
  }, []);
  
  
  // تطبيق الفلترة عند تحميل الصفحة بعد تحميل الأعضاء
  useEffect(() => {
    if (members.length > 0) {
      filterMembers(); // تطبيق الفلترة بعد تحميل الأعضاء
    }
  }, [members]);
  

// استدعاء `filterMembers` عند تحميل الصفحة
useEffect(() => {
    filterMembers(); // تطبيق الفلترة عند تحميل الصفحة
}, [statusFilter, areaFilter, neighborhoodFilter, letterFilter, buildingNumberFilter, floorFilter, unitNumberFilter, members]);

useEffect(() => {
  if (members.length > 0) {
    filterMembers(); // تطبيق الفلترة بعد تحميل الأعضاء
  }
}, [members]);


const handleSendInvitation = async (member) => {
  try {
    const emailData = {
      email: member.info.email, // الإيميل المسجل للعضو
      fullName: member.info.full_name_ar, // الاسم الكامل
      status: member.info.status, // الحالة
      area: getAreaName(member.info.area_id), // المنطقة
      neighborhood: getNeighborhoodName(member.info.neighborhood_id), // الحي
      letter: getLetterName(member.info.letter_id), // الحرف
      phone_number: member.info.phone_number, // رقم الهاتف
      whatsapp_number: member.info.whatsapp_number, // رقم الواتساب
    };

    // إرسال البيانات إلى خادم API للتعامل مع Mailgun
    const response = await axios.post(`${baseURL}send-invitation.php`, emailData);

    if (response.data.success) {
      toast.success("تم إرسال الدعوة بنجاح");
    } else {
      toast.error("حدث خطأ أثناء إرسال الدعوة");
    }
  } catch (error) {
    console.error("Error sending invitation:", error);
    toast.error("حدث خطأ أثناء إرسال الدعوة");
  }
};


  useEffect(() => {
    let filtered = members;
  
    // تطبيق البحث إذا كان هناك مصطلح بحث
    if (searchTerm.trim() !== '') {
      const searchValueLower = searchTerm.trim().toLowerCase();
      const letterAndBuildingPattern = /([a-zA-Z])\s?(\d+)/;
      const searchParts = searchValueLower.split(/\s+/);
  
      filtered = filtered.filter((member) => {
        const neighborhoodName = getNeighborhoodName(member.info.neighborhood_id).toLowerCase();
        const areaName = getAreaName(member.info.area_id).toLowerCase();
        const letterName = getLetterName(member.info.letter_id).toLowerCase();
        const buildingNumber = member.info.building_number.toString();
  
        const matchesLetterAndBuilding = letterAndBuildingPattern.test(searchValueLower) && (() => {
          const match = searchValueLower.match(letterAndBuildingPattern);
          const searchLetter = match[1].toLowerCase();
          const searchBuilding = match[2];
  
          return letterName === searchLetter && buildingNumber === searchBuilding;
        })();
  
        const matchesNeighborhood = neighborhoodName.includes(searchValueLower);
        const matchesArea = areaName.includes(searchValueLower);
  
        let matchesComplexSearch = false;
        if (searchParts.length === 2) {
          const part1 = searchParts[0];
          const part2 = searchParts[1];
  
          matchesComplexSearch =
            (neighborhoodName.includes(part1) && letterName === part2[0] && buildingNumber === part2.slice(1)) ||
            (letterName === part1 && buildingNumber === part2);
        }
  
        return (
          matchesNeighborhood ||
          matchesArea ||
          matchesLetterAndBuilding ||
          matchesComplexSearch
        );
      });
    } else {
      // تطبيق الفلاتر فقط إذا لم يكن هناك مصطلح بحث
      // فلترة الحالة
      if (statusFilter !== "all") {
        filtered = filtered.filter((member) => member.info.status === statusFilter);
      }
  
      // فلترة المنطقة
      if (areaFilter !== "all") {
        filtered = filtered.filter((member) => member.info.area_id === areaFilter);
      }
  
      // فلترة الحي
      if (neighborhoodFilter !== "all") {
        filtered = filtered.filter((member) => member.info.neighborhood_id === neighborhoodFilter);
      }
  
      // فلترة الحرف
      if (letterFilter !== "all") {
        filtered = filtered.filter((member) => member.info.letter_id === letterFilter);
      }
  
      // فلترة رقم العمارة
      if (buildingNumberFilter !== "all" && buildingNumberFilter !== "") {
        filtered = filtered.filter((member) =>
          member.info.building_number === buildingNumberFilter
        );
      }
    }
  
    setFilteredMembers(filtered);
    setTotalItemsCount(filtered.length);
  }, [
    statusFilter,
    areaFilter,
    neighborhoodFilter,
    letterFilter,
    buildingNumberFilter,
    searchTerm,
    members,
  ]);
  
  // استدعاء الفلترة عند تحميل الصفحة لأول مرة
  useEffect(() => {
    fetchMembers(); // جلب جميع الأعضاء عند تحميل الصفحة
  }, []);

  // استدعاء الفلترة عند تغيير أي فلتر
  useEffect(() => {
    filterMembers(); // تطبيق الفلترة عند تحميل الصفحة
  }, [statusFilter, areaFilter, neighborhoodFilter, letterFilter, buildingNumberFilter , floorFilter, unitNumberFilter ,sortField,sortOrder, members]);

  const handleAreaChange = (e) => {
    const areaId = e.target.value;
    setAreaFilter(areaId);
    setNeighborhoodFilter("all");
    setLetterFilter("all");
    if (areaId !== "all") {
      fetchNeighborhoods(areaId);  // جلب الأحياء الخاصة بالمنطقة المختارة
    } else {
      setNeighborhoods([]);  // إعادة ضبط الأحياء عند اختيار "كل المناطق"
      setLetters([]);  // إعادة ضبط الحروف عند اختيار "كل المناطق"
    }
  };

  const handleNeighborhoodChange = (e) => {
    const neighborhoodId = e.target.value;
    setNeighborhoodFilter(neighborhoodId);
    setLetterFilter("all");
    if (neighborhoodId !== "all") {
      fetchLetters(neighborhoodId);  // جلب الحروف الخاصة بالحي المختار
    } else {
      setLetters([]);  // إعادة ضبط الحروف عند اختيار "كل الأحياء"
    }
  };

  const handleLetterChange = (e) => {
    const letterId = e.target.value;
    setLetterFilter(letterId);
    if (letterId !== "all") {
      fetchBuildings(letterId); // جلب العمارات بناءً على الحرف المختار
    } else {
      setBuildings([]); // إعادة ضبط العمارات إذا تم اختيار "كل الحروف"
    }
  };

  const handleViewContract = () => {
    setShowContractModal(true);
  };

  const handleEditMember = (member) => {
    setSelectedMember(member);
    setFormData({ ...member.info });
    setShowEditModal(true);
    setActiveTab("personalInfo"); // تأكد من فتح التبويب الأول عند عرض المودال
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(
        `${baseURL}members.php?endpoint=updateMember&id=${selectedMember.info.id}`,
        formData
      );
      setShowEditModal(false);
      toast.success("تم حفظ التعديلات بنجاح");
      fetchMembers();
    } catch (error) {
      toast.error("حدث خطأ أثناء حفظ التعديلات");
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.2);
  };

  const handleZoomOut = () => {
    if (zoom > 1) setZoom((prevZoom) => prevZoom - 0.2);
  };

  const handlePdfNextPage = () => {
    if (pdfPage < numPdfPages) setPdfPage(pdfPage + 1);
  };

  const handlePdfPrevPage = () => {
    if (pdfPage > 1) setPdfPage(pdfPage - 1);
  };

  const onPdfLoadSuccess = ({ numPages }) => {
    setNumPdfPages(numPages);
  };
  const handleSort = (field) => {
    const isSameField = sortField === field;
    const newSortOrder = isSameField ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
  
    setSortField(field);  // تحديث حقل الفرز
    setSortOrder(newSortOrder);  // تحديث ترتيب الفرز
  
  
    // فرز الأعضاء بناءً على الحقل المختار
    const sortedMembers = [...filteredMembers].sort((a, b) => {
      let fieldA = a.info[field];
      let fieldB = b.info[field];
  
      // تحقق من وجود القيم قبل المقارنة
      if (!fieldA || !fieldB) {
        console.log('Missing fields:', fieldA, fieldB);
        return 0;
      }
  
      // طباعة القيم للتحقق منها
      console.log('Comparing:', fieldA, fieldB);
  
      // مقارنة القيم بناءً على النوع (نصوص أو أرقام)
      if (typeof fieldA === 'string') {
        fieldA = fieldA.toLowerCase();
        fieldB = fieldB.toLowerCase();
      }
  
      if (newSortOrder === 'asc') {
        return fieldA > fieldB ? 1 : -1;
      } else {
        return fieldA < fieldB ? 1 : -1;
      }
    });
  
    // طباعة القائمة المرتبة للتأكد
    console.log('Sorted Members:', sortedMembers);
  
    // تحديث حالة filteredMembers بالقائمة المرتبة
    setFilteredMembers(sortedMembers);
  };
  
  
  
// إضافة الحقول الخاصة بالبحث (الاسم ورقم التليفون)
const handleNameSearch = (nameValue) => {
  setFilteredMembers(
    members.filter((member) =>
      member.info.full_name_ar.toLowerCase().includes(nameValue.toLowerCase())
    )
  );
};

const handlePhoneSearch = (phoneValue) => {
  setFilteredMembers(
    members.filter((member) =>
      member.info.phone_number.includes(phoneValue)
    )
  );
};

  const sortingOptions = [
    {
      value: 'name',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaUser style={{ marginLeft: 5 }} /> الاسم
        </div>
      ),
    },
    {
      value: 'status',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaAlignLeft style={{ marginLeft: 5 }} /> الحالة
        </div>
      ),
    },
    {
      value: 'area',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCity style={{ marginLeft: 5 }} /> المنطقة
        </div>
      ),
    },
    {
      value: 'neighborhood',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaBuilding style={{ marginLeft: 5 }} /> الحي
        </div>
      ),
    },
    {
      value: 'letter',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaFont style={{ marginLeft: 5 }} /> الحرف
        </div>
      ),
    },
    {
      value: 'building_number',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaHashtag style={{ marginLeft: 5 }} /> العمارة
        </div>
      ),
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 30,
      padding: '5px 10px',
      fontSize: 16,
      borderColor: '#1E88E5',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#1E88E5',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected ? '#1E88E5' : '#fff',
      '&:hover': {
        backgroundColor: '#E3F2FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: '#666',
    }),
  };

  
  

  return (
    <div className="admin-dashboard container">
      <ToastContainer />
      {/* إضافة الشعار في منتصف أعلى الصفحة */}
      <div className="logo-container text-center my-4">
      <img src="assets/img/logo/logo-dark.png" alt="Logo" className="logo" />
      <h1 className="page-title">إدارة أعضاء الإتحاد</h1>
      </div>

      {/* حقل البحث */}
      <InputGroup className="mb-3 search-input">
        <FormControl
          placeholder="بحث..."
          onChange={(e) => handleSearch(e.target.value)}
        />
      </InputGroup>

      {/* عرض عدد الأعضاء المتوفرين */}
<div className="mb-3 filtered-count d-flex justify-content-center algin-items-center flex-row">
  عدد الأعضاء المتوفرين: {filteredMembers.length}
</div>

<div className="container ">
  {/* شريط الأيقونات للترتيب */}
<div className="mb-4 sorting-icons  d-flex justify-content-center algin-items-center flex-wrap   ">
  <Button variant="light" onClick={() => handleSort('name')} title="ترتيب حسب الاسم">
    <FaUser />
    {sortField === 'name' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
  <Button variant="light" onClick={() => handleSort('status')} title="ترتيب حسب الحالة">
    <FaAlignLeft />
    {sortField === 'status' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
  <Button variant="light" onClick={() => handleSort('area')} title="ترتيب حسب المنطقة">
    <FaCity />
    {sortField === 'area' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
  <Button variant="light" onClick={() => handleSort('neighborhood')} title="ترتيب حسب الحي">
    <FaBuilding />
    {sortField === 'neighborhood' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
  <Button variant="light" onClick={() => handleSort('letter')} title="ترتيب حسب الحرف">
    <FaFont />
    {sortField === 'letter' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
  <Button variant="light" onClick={() => handleSort('building_number')} title="ترتيب حسب العمارة">
    <FaHashtag />
    {sortField === 'building_number' && (sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />)}
  </Button>
</div>
</div>



      {/* الفلاتر */}
      <Row className="mb-4">
        <Col md={3}>
          <Form.Control as="select" onChange={handleAreaChange} value={areaFilter}>
            <option value="all">كل المناطق</option>
            {areas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Form.Control>
        </Col>

        <Col md={3}>
          <Form.Control as="select" onChange={handleNeighborhoodChange} value={neighborhoodFilter}>
            <option value="all">كل الأحياء</option>
            {neighborhoods.map((neighborhood) => (
              <option key={neighborhood.id} value={neighborhood.id}>
                {neighborhood.name}
              </option>
            ))}
          </Form.Control>
        </Col>

        <Col md={3}>
          <Form.Control as="select" onChange={handleLetterChange} value={letterFilter}>
            <option value="all">كل الحروف</option>
            {letters.map((letter) => (
              <option key={letter.id} value={letter.id}>
                {letter.letter}
              </option>
            ))}
          </Form.Control>
        </Col>

        <Col md={3}>
          <Form.Control as="select" onChange={handleBuildingChange} value={buildingNumberFilter}>
            <option value="all">كل العمارات</option>
            {buildings.map((buildingNumber) => (
              <option key={buildingNumber} value={buildingNumber}>
                {buildingNumber}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>


      <Row className="mb-4">
  {/* فلتر الطابق */}
<Col md={3}>
  <Form.Control
    as="select"
    onChange={(e) => setFloorFilter(e.target.value)}
    value={floorFilter}
  >
    <option value="كل الطوابق">كل الطوابق</option>
    <option value="أرضي">أرضي</option>
    <option value="أرضي مع بيزمينت">أرضي مع بيزمينت</option>
    <option value="أول علوي">أول علوي</option>
    <option value="ثاني علوي">ثاني علوي</option>
    <option value="ثالث علوي">ثالث علوي</option>
    <option value="ثالث مع روف ">ثالث مع روف </option>
    <option value="روف">روف</option>
  </Form.Control>
</Col>


  {/* فلتر رقم الشقة */}
  <Col md={3}>
  <Form.Control
    type="text"
    placeholder="رقم الشقة"
    value={unitNumberFilter}
    onChange={(e) => setUnitNumberFilter(e.target.value)}
  />
</Col>
<Row className="mb-4">
  <Col md={6}>
    <InputGroup className="mb-3">
      <FormControl
        placeholder="بحث بالاسم..."
        onChange={(e) => handleNameSearch(e.target.value)}
      />
    </InputGroup>
  </Col>

  <Col md={6}>
    <InputGroup className="mb-3">
      <FormControl
        placeholder="بحث برقم التليفون..."
        onChange={(e) => handlePhoneSearch(e.target.value)}
      />
    </InputGroup>
  </Col>
</Row>


</Row>











      {/* فلتر بالحالة */}
      <Row className="mb-4">
        <Col md={3}>
          <Form.Control
            as="select"
            onChange={(e) => setStatusFilter(e.target.value)}
            value={statusFilter}
          >
            <option value="all">كل الحالات</option>
            <option value="تم التسجيل">تم التسجيل</option>
            <option value="مرفوض">مرفوض</option>
            <option value="معلق">معلق</option>
            <option value="تحت المراجعة">تحت المراجعة</option>
          </Form.Control>
        </Col>
      </Row>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          {filteredMembers
            .slice(
              (activePage - 1) * itemsCountPerPage,
              activePage * itemsCountPerPage
            )
            .map((member, idx) => (
              <Col md={4} key={idx} className="mb-4">
                <Card
                  className="member-card"
                  style={{
                    backgroundColor: cardBgColor(member.info.status),
                    color: textColor(member.info.status),
                  }}
                >
                  <Card.Body>
                    <Card.Title>{member.info.full_name_ar}</Card.Title>
                    <Card.Subtitle className="mb-2">
                      <strong>الحالة:</strong> {member.info.status}
                    </Card.Subtitle>

                    <Card.Text>
                      <div className="text-black">
                        <strong>المنطقة:</strong>{" "}
                        {getAreaName(member.info.area_id)}
                        <br />
                        <strong>الحي:</strong>{" "}
                        {getNeighborhoodName(member.info.neighborhood_id)}
                        <br />
                        <strong>الحرف:</strong>{" "}
                        {getLetterName(member.info.letter_id)}
                        <br />
                        <strong>العمارة:</strong> {member.info.building_number}
                      </div>
                    </Card.Text>

                    <div className="d-flex justify-content-center">
  <Button
    variant="primary"
    onClick={() => handleEditMember(member)}
    className="view-button"
  >
    <FaEye /> مشاهدة
  </Button>

  <Button
    variant="success"
    onClick={() => handleSendInvitation(member)}
    className="ml-2 send-invitation-button"
  >
    <FaEnvelope /> إرسال الدعوة
  </Button>
</div>

                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      )}

      {/* مودال عرض بيانات العضو */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>مشاهدة بيانات العضو</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
            {/* تبويب المعلومات الشخصية */}
            <Tab eventKey="personalInfo" title="المعلومات الشخصية">
              <Form.Group>
                <Form.Label>الاسم الكامل:</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.full_name_ar || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>سنة الميلاد:</Form.Label>
                <Form.Control
                  type="number"
                  value={formData.birth_year || ''}
                  disabled
                />
              </Form.Group>
            </Tab>

            {/* تبويب معلومات السكن */}
            <Tab eventKey="housingInfo" title="معلومات السكن">
              <Form.Group>
                <Form.Label>المنطقة:</Form.Label>
                <Form.Control
                  type="text"
                  value={getAreaName(formData.area_id)}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>الحي:</Form.Label>
                <Form.Control
                  type="text"
                  value={getNeighborhoodName(formData.neighborhood_id)}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>الحرف:</Form.Label>
                <Form.Control
                  type="text"
                  value={getLetterName(formData.letter_id)}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>العمارة:</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.building_number || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>الطابق:</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.floor_number || ''}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>رقم الشقة:</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.unit_number || ''}
                  disabled
                />
              </Form.Group>
            </Tab>

            {/* تبويب معلومات التواصل */}
            <Tab eventKey="contactInfo" title="معلومات التواصل">
              <Form.Group>
                <Form.Label>البريد الإلكتروني:</Form.Label>
                <Form.Control
                  type="email"
                  value={formData.email || 'غير متوفر'}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>رقم الهاتف:</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={formData.phone_number || 'غير متوفر'}
                    disabled
                  />
                  <Button variant="success" className="ml-2" onClick={() => window.open(`https://wa.me/2${formData.phone_number}`)}>
                    <FaWhatsapp />
                  </Button>
                  <Button variant="info" className="ml-2" onClick={() => navigator.clipboard.writeText(formData.phone_number)}>
                    نسخ
                  </Button>
                  {window.innerWidth <= 768 && (
                    <Button variant="primary" className="ml-2" onClick={() => window.open(`tel:${formData.phone_number}`)}>
                      اتصال
                    </Button>
                  )}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>رقم الواتس آب:</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={formData.whatsapp_number || 'غير متوفر'}
                    disabled
                  />
                  <Button variant="success" className="ml-2" onClick={() => window.open(`https://wa.me/${formData.whatsapp_number}`)}>
                    <FaWhatsapp />
                  </Button>
                  <Button variant="info" className="ml-2" onClick={() => navigator.clipboard.writeText(formData.whatsapp_number)}>
                    نسخ
                  </Button>
                  {window.innerWidth <= 768 && (
                    <Button variant="primary" className="ml-2" onClick={() => window.open(`tel:${formData.whatsapp_number}`)}>
                      اتصال
                    </Button>
                  )}
                </div>
              </Form.Group>
            </Tab>

            {/* تبويب معلومات الشركة */}
            <Tab eventKey="companyInfo" title="معلومات الشركة">
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>اسم الشركة</td>
                    <td>{formData.developer_company_name || 'غير متوفر'}</td>
                  </tr>
                  <tr>
                    <td>تقييم الشركة</td>
                    <td>
                      {Array.from({ length: 5 }, (_, index) => (
                        <span key={index} style={{ color: index < formData.developer_rating ? 'gold' : 'gray' }}>
                          &#9733;
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>التسليم في الموعد</td>
                    <td>{formData.on_time_delivery}</td>
                  </tr>
                  <tr>
                    <td>التعاون مع الشركة</td>
                    <td>{formData.company_collaboration}</td>
                  </tr>
                  <tr>
                    <td>تقييم جودة البناء</td>
                    <td>
                      {Array.from({ length: 5 }, (_, index) => (
                        <span key={index} style={{ color: index < formData.build_quality_rating ? 'gold' : 'gray' }}>
                          &#9733;
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>تقييم جودة التشطيب</td>
                    <td>
                      {Array.from({ length: 5 }, (_, index) => (
                        <span key={index} style={{ color: index < formData.finish_quality_rating ? 'gold' : 'gray' }}>
                          &#9733;
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>ملاحظات على المخالفات</td>
                    <td>{formData.noticed_violations}</td>
                  </tr>
                  <tr>
                    <td>تفاصيل المخالفات</td>
                    <td>{formData.violation_details}</td>
                  </tr>
                </tbody>
              </Table>
            </Tab>

            {/* تبويب عرض المرفق */}
            <Tab eventKey="attachment" title="المرفق">
              {formData.contract_file?.endsWith('.pdf') ? (
                <div>
                  <Document
                    file={`http://b-watan.net/${formData.contract_file}`}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <div className="d-flex justify-content-between mt-2">
                    <Button onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)}>السابق</Button>
                    <Button onClick={() => setPageNumber(pageNumber + 1)}>التالي</Button>
                  </div>
                  <p>صفحة {pageNumber} من {numPages}</p>
                </div>
              ) : (
                <div>
                  <Zoom>
                    <img
                      src={`http://b-watan.net/${formData.contract_file}`}
                      alt="attachment"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Zoom>
                </div>
              )}
            </Tab>

            {/* تبويب تغيير الحالة */}
            <Tab eventKey="statusChange" title="تغيير الحالة">
              <Form.Group>
                <Form.Label>الحالة</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}  // تحديث حالة status
                >
                  <option value="">اختر الحالة</option>
                  <option value="تم التسجيل">تم التسجيل</option>
                  <option value="مرفوض">مرفوض</option>
                  <option value="معلق">معلق</option>
                  <option value="تحت المراجعة">تحت المراجعة</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>سبب تغيير الحالة</Form.Label>
                <Form.Control
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}  // تحديث سبب تغيير الحالة
                />
              </Form.Group>

              <Button variant="primary" onClick={handleSaveStatus}>
                حفظ التغييرات
              </Button>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />

      {/* باقي المودالات والتوست والتنبيهات كما هي بدون تغيير */}

      <style>{`
        .admin-dashboard {
          font-family: 'Cairo', sans-serif;
          color: #333;
        }

        .logo-container .logo {
          width: 80px;
          height: auto;
          margin-bottom: 10px;
          animation: fadeInDown 1s;
        }

        .page-title {
          font-size: 32px;
          font-weight: bold;
          color: #1E88E5;
          animation: fadeInUp 1s;
        }

        .search-input .form-control {
          border-radius: 30px;
          padding: 15px;
          font-size: 16px;
          border: 2px solid #1E88E5;
        }

        .btn {
          border-radius: 30px;
          padding: 10px 20px;
          font-size: 16px;
        }

        /* تنسيق عناصر التحكم بالترتيب */
.sorting-controls .form-control {
  border-radius: 30px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #1E88E5;
  margin-bottom: 15px;
}


  .filtered-count {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
        .member-card {
          border-radius: 15px;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s;
        }

        .member-card:hover {
          transform: translateY(-10px);
        }

        .view-button {
          background-color: #1E88E5;
          border: none;
        }

        .view-button:hover {
          background-color: #1565C0;
        }
.sorting-icons {

}

.sorting-icons .btn {
  margin: 0 5px;
  font-size: 24px;
  color: #1E88E5;
  background-color: transparent;
  border: none;
}

.sorting-icons .btn:hover {
  color: #1565C0;
}

.sorting-icons .btn:focus {
  outline: none;
  box-shadow: none;
}

.sorting-icons .btn .fa-sort-up,
.sorting-icons .btn .fa-sort-down {
  margin-left: 3px;
  font-size: 14px;
}


.sorting-icons .btn:hover {
  color: #1565C0;
}

.sorting-icons .btn:focus {
  outline: none;
  box-shadow: none;
}

.sorting-icons .btn .fa-sort-up,
.sorting-icons .btn .fa-sort-down {
  margin-left: 3px;
  font-size: 14px;
}


        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @media screen and (max-width: 768px) {
          .btn, .form-control {
            font-size: 14px;
          }

          .page-title {
            font-size: 24px;
          }
        }
      `}</style>
    </div>
  );
};

export default AdminDashboard;
