import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './MemberRegistration.css'; // ملف CSS المخصص للتصميم
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Policies from './pol';
import Modal from 'react-modal'; // إضافة Modal Popup
import StarRatings from 'react-star-ratings'; // مكتبة لتقييم النجوم
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


Modal.setAppElement('#root'); // تعيين عنصر التطبيق الرئيسي لـ Modal

const MemberRegistration = () => {
  const [member, setMember] = useState({
    full_name_ar: '',
    birth_year: null,
    phone_number: '',
    whatsapp_number: '',
    email: '',
    role: '',
    area_id: '',
    neighborhood_id: '',
    letter_id: '',
    building_number: '',
    is_resident: 'نعم',
    intended_move_year: null,
    contract_file: null,
    agree_terms: false,
    developer_company_name: '',
    developer_rating: 0,
    build_quality_rating: 0,
    finish_quality_rating: 0,
    on_time_delivery: 'نعم',
    company_collaboration: 'نعم',
    noticed_violations: 'لا', // القيمة الافتراضية 'لا'
    violation_details: '', // حقل النص لتفاصيل المخالفات
  });

  const [errors, setErrors] = useState({});
  const [areas, setAreas] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [letters, setLetters] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [showMoveYear, setShowMoveYear] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [isPhoneSameAsWhatsapp, setIsPhoneSameAsWhatsapp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false); // التحقق من الهاتف
  const [isEmailVerified, setIsEmailVerified] = useState(false); // التحقق من البريد الإلكتروني
  const [activeTab, setActiveTab] = useState(0); // التحكم في التاب النشط
  const [isModalOpen, setIsModalOpen] = useState(false); // الحالة الخاصة بـ Modal
  

  // Fetch areas on mount
  useEffect(() => {
    axios
      .get('https://b-watan.net/app.php?endpoint=getAreas')
      .then((response) => setAreas(response.data))
      .catch(() => toast.error('Error fetching areas'));
  }, []);

  // التحقق من الاسم العربي
  const validateFullName = (name) => {
    const arabicRegex = /^[\u0600-\u06FF\s]{3,}$/;
    return arabicRegex.test(name) && name.trim().split(' ').length >= 3;
  };

  // التحقق من رقم الهاتف
  const validatePhoneNumber = (phone) => {
    // الرقم الكامل من PhoneInput يجب أن يحتوي على 8 أرقام على الأقل بعد كود الدولة
    return phone.length >= 8; 
  };
  
  

  const canProceedToNextTab = () => {
    if (activeTab === 0) {
      // التحقق من الموافقة على السياسات
      return member.agree_terms;
    } else if (activeTab === 1) {
      // التحقق من البيانات الشخصية فقط إذا كنا في التبويب الثاني
      return member.full_name_ar && validatePhoneNumber(member.phone_number) && validateEmail(member.email);
    } else if (activeTab === 2) {
      // التحقق من بيانات السكن فقط إذا كنا في التبويب الثالث
      return member.area_id && member.neighborhood_id && member.letter_id && member.building_number;
    } else if (activeTab === 3 && member.role === 'مالك وحدة سكنية') {
      // التحقق من بيانات المطور العقاري فقط إذا كنا في التبويب الرابع
      return member.developer_company_name && member.developer_rating && member.build_quality_rating;
    }
    return true; // السماح بالانتقال لبقية الحالات
  };
  
  
  
  
  const handleNextButtonClick = () => {
    if (activeTab === 0 && !member.agree_terms) {
      toast.error("يجب الموافقة على السياسات والأحكام قبل متابعة التسجيل");
      return;
    }
  
    if (activeTab === 1) {
      if (!isPhoneVerified || !isEmailVerified) {
        toast.error("يجب التحقق من رقم الهاتف والبريد الإلكتروني أولاً");
        return;
      }
      if (!member.full_name_ar || !validatePhoneNumber(member.phone_number) || !validateEmail(member.email)) {
        toast.error("يرجى إدخال جميع البيانات الشخصية المطلوبة");
        return;
      }
    }
  
    if (activeTab === 2) {
      if (!member.area_id || !member.neighborhood_id || !member.letter_id || !member.building_number) {
        toast.error("يرجى إدخال معلومات السكن كاملة قبل الانتقال");
        return;
      }
    }
  
    if (activeTab === 3 && member.role === 'مالك وحدة سكنية') {
      if (!member.developer_company_name || !member.developer_rating || !member.build_quality_rating) {
        toast.error("يرجى إكمال جميع تفاصيل شركة التطوير العقاري");
        return;
      }
    }
  
    // الانتقال إلى التاب التالي إذا تحقق جميع الشروط للتبويب الحالي
    setActiveTab((prevTab) => prevTab + 1);
  };
  
  
  

  // // التحقق من البريد الإلكتروني
  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|icloud\.com|aol\.com|protonmail\.com|zoho\.com|gmx\.com|yandex\.com|mail\.com|me\.com)$/;
  //   return emailRegex.test(email);
  // };
  
  // التحقق من البريد الإلكتروني
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


  // التحقق من رقم الهاتف في قاعدة البيانات قبل المتابعة
  const checkPhoneNumber = (phone) => {
    axios
      .get(`https://b-watan.net/api.php?endpoint=checkPhone&phone_number=${phone}`)
      .then((response) => {
        if (response.data.exists) {
          // عرض رسالة إشعار
          toast.error(`هذا الرقم مسجل مسبقاً. الحالة: ${response.data.status}`, {
            autoClose: 5000, // يتم إغلاق الرسالة بعد 5 ثوانٍ
          });
          setErrors({ ...errors, phone_number: 'رقم الهاتف مسجل مسبقاً' });
          setIsPhoneVerified(false); // إيقاف التحقق
          setIsSubmitting(false);
        } else {
          setErrors({ ...errors, phone_number: '' });
          setIsPhoneVerified(true); // تم التحقق بنجاح
        }
      })
      .catch(() => {
        toast.error('حدث خطأ أثناء التحقق من رقم الهاتف.');
        setIsPhoneVerified(false); // فشل التحقق
      });
  };

  // التحقق من البريد الإلكتروني
  const checkEmail = (email) => {
    axios
      .get(`https://b-watan.net/api.php?endpoint=checkEmail&email=${email}`)
      .then((response) => {
        if (response.data.exists) {
          // عرض رسالة إشعار
          toast.error(`هذا البريد الإلكتروني مسجل مسبقاً. الحالة: ${response.data.status}`, {
            autoClose: 5000, // يتم إغلاق الرسالة بعد 5 ثوانٍ
          });
          setErrors({ ...errors, email: 'البريد الإلكتروني مسجل مسبقاً' });
          setIsEmailVerified(false); // إيقاف التحقق
          setIsSubmitting(false);
        } else {
          setErrors({ ...errors, email: '' });
          setIsEmailVerified(true); // تم التحقق بنجاح
        }
      })
      .catch(() => {
        toast.error('حدث خطأ أثناء التحقق من البريد الإلكتروني.');
        setIsEmailVerified(false); // فشل التحقق
      });
  };

  // التحقق من البيانات عند تغيير رقم الهاتف
  const handlePhoneNumberChange = (phone, country) => {
    setMember({ ...member, phone_number: phone });
  
    // تحقق من صحة الرقم
    if (!validatePhoneNumber(phone)) {
      setErrors({ ...errors, phone_number: 'الرجاء إدخال رقم هاتف صحيح بناءً على الدولة المختارة' });
      setIsPhoneVerified(false);  // إذا لم يكن الرقم صحيحًا، قم بإلغاء التحقق
    } else {
      setErrors({ ...errors, phone_number: '' });
  
      // بعد التحقق من صحة الرقم، قم بإرسال الطلب للتحقق من أن الرقم غير مسجل مسبقًا
      axios
        .get(`https://b-watan.net/api.php?endpoint=checkPhone&phone_number=${phone}`)
        .then((response) => {
          
          if (response.data.exists) {
            // إذا كان الرقم مسجلًا مسبقًا، عرض رسالة خطأ
            toast.error(`هذا الرقم مسجل مسبقاً. الحالة: ${response.data.status}`, {
              autoClose: 5000, // يتم إغلاق الرسالة بعد 5 ثوانٍ
            });
            setErrors({ ...errors, phone_number: 'رقم الهاتف مسجل مسبقاً' });
            setIsPhoneVerified(false); // إيقاف التحقق
          } else {
            // إذا لم يكن الرقم مسجلًا مسبقًا، السماح بالتحقق
            setErrors({ ...errors, phone_number: '' });
            setIsPhoneVerified(true); // التحقق بنجاح
          }
        })
        .catch(() => {
          // التعامل مع أي خطأ يحدث أثناء التحقق من الرقم
          toast.error('حدث خطأ أثناء التحقق من رقم الهاتف.');
          setIsPhoneVerified(false); // فشل التحقق
        });
    }
  };
  

  // التحقق من البيانات عند تغيير البريد الإلكتروني
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setMember({ ...member, email: email });
    handleValidation('email', email);
    checkEmail(email); // التحقق من البريد الإلكتروني عبر API
  };

  // Handle area change and fetch neighborhoods
  const handleAreaChange = (selectedOption) => {
    if (!isPhoneVerified || !isEmailVerified) return; // تعطيل إذا لم يتم التحقق من الهاتف والبريد
    setMember({ ...member, area_id: selectedOption.value });
    setNeighborhoods([]);
    setLetters([]);
    setBuildings([]);
    axios
      .get(`https://b-watan.net/app.php?endpoint=getNeighborhoods&area_id=${selectedOption.value}`)
      .then((response) => setNeighborhoods(response.data))
      .catch(() => toast.error('Error fetching neighborhoods'));
  };

  // Handle neighborhood change and fetch letters
  const handleNeighborhoodChange = (selectedOption) => {
    setMember({ ...member, neighborhood_id: selectedOption.value });
    setLetters([]);
    setBuildings([]);
    axios
      .get(`https://b-watan.net/app.php?endpoint=getLetters&neighborhood_id=${selectedOption.value}`)
      .then((response) => {
        const lettersForNeighborhood = response.data.filter(
          (letter) => letter.neighborhood_id === selectedOption.value
        );
        setLetters(lettersForNeighborhood);
      })
      .catch(() => toast.error('Error fetching letters'));
  };

  // Handle letter change and fetch building numbers
  const handleLetterChange = (selectedOption) => {
    setMember({ ...member, letter_id: selectedOption.value });
    axios
      .get(`https://b-watan.net/total_buildings.php?endpoint=getBuildings&letter_id=${selectedOption.value}`)
      .then((response) => {
        const totalBuildings = response.data.total_buildings;
        const buildingNumbers = Array.from({ length: totalBuildings }, (_, i) => ({
          value: i + 1,
          label: i + 1,
        }));
        setBuildings(buildingNumbers);
        // toast.success(`عدد العمارات: ${totalBuildings}`);
      })
      .catch(() => "");
  };

  // Handle resident change
  const handleResidentChange = (selectedOption) => {
    setMember({ ...member, is_resident: selectedOption.value });
    setShowMoveYear(true);
  };

  // Handle file change and preview
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type.startsWith('image/'))) {
      setMember({ ...member, contract_file: file });
      setFilePreview(URL.createObjectURL(file));
      setErrors({ ...errors, contract_file: '' });
    } else {
      setErrors({ ...errors, contract_file: 'يجب رفع ملف بصيغة PDF أو صورة' });
    }
  };

  // Handle WhatsApp number same as phone number
  const handlePhoneSameAsWhatsapp = (e) => {
    const checked = e.target.checked;
    setIsPhoneSameAsWhatsapp(checked);
    if (checked) {
      setMember({ ...member, whatsapp_number: `2${member.phone_number}` });
    } else {
      setMember({ ...member, whatsapp_number: '' });
    }
  };

  // Handle validation for each input field and show immediate feedback
  const handleValidation = (field, value) => {
    let error = '';
    switch (field) {
      case 'full_name_ar':
        if (!validateFullName(value)) {
          error = 'الاسم الكامل يجب أن يكون باللغة العربية ويحتوي على 3 كلمات على الأقل';
        }
        break;
      case 'phone_number':
        if (!validatePhoneNumber(value)) {
          error = 'يجب أن يكون رقم الهاتف المصري صحيحًا (01xxxxxxxxx)';
        }
        break;
      case 'email':
        if (!validateEmail(value)) {
          error = 'يجب إدخال بريد إلكتروني صالح';
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: error });
  };

  // التحقق من البيانات عند الإرسال
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // التحقق من الموافقة على السياسات في كل من تبويب السياسات وتبويب إثبات الملكية
    if (!member.agree_terms) {
      toast.error('يجب الموافقة على السياسات والأحكام قبل إرسال البيانات');
      return;
    }
  
    if (!validateFullName(member.full_name_ar)) {
      setErrors({ ...errors, full_name_ar: 'يجب أن يحتوي الاسم الكامل على ثلاث كلمات على الأقل وباللغة العربية' });
      return;
    }
    if (!validatePhoneNumber(member.phone_number)) {
      setErrors({ ...errors, phone_number: 'يجب أن يكون رقم الهاتف المصري صحيحًا (01xxxxxxxxx)' });
      return;
    }
    if (!validateEmail(member.email)) {
      setErrors({ ...errors, email: 'يجب إدخال بريد إلكتروني صالح' });
      return;
    }
  
    const formData = new FormData();
    formData.append('contract_file', member.contract_file);
    formData.append('noticed_violations', member.noticed_violations);
    formData.append('violation_details', member.violation_details);
    
        // استبدال القيم الفارغة الخاصة بالتقييمات بـ 0
    formData.append('developer_rating', member.developer_rating || 0);
    formData.append('build_quality_rating', member.build_quality_rating || 0);
    formData.append('finish_quality_rating', member.finish_quality_rating || 0);
    formData.append('on_time_delivery', member.on_time_delivery || 'لا'); // إذا كانت نصية، يمكنك تعديل القيمة الافتراضية حسب الحاجة
    formData.append('company_collaboration', member.company_collaboration || 'لا');


  
    Object.keys(member).forEach((key) => {
      if (key !== 'contract_file') {
        formData.append(key, member[key]);
      }
    });
  
    setIsSubmitting(true);
  
    axios.post('https://b-watan.net/api.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        if (response.data.success) {
          toast.success('تم التسجيل بنجاح');
          setIsModalOpen(true);
        } else {
          toast.error(`حدث خطأ أثناء التسجيل: ${response.data.message || 'غير معروف'}`);
        }
      })
      .catch(error => {
        toast.error('حدث خطأ أثناء التسجيل. تأكد من إدخال البيانات بشكل صحيح.');
      });
  };
  

  // إغلاق الـ Modal والعودة إلى الصفحة الرئيسية
  const closeModal = () => {
    setIsModalOpen(false);
    window.location.href = '/'; // إعادة توجيه المستخدم إلى الصفحة الرئيسية
  };

  // التعامل مع التنقل بين التابات بناءً على التحقق
  const handleTabSwitch = (newTabIndex) => {
    if (newTabIndex > activeTab) {
      // التحقق من الموافقة على الشروط والأحكام عند محاولة الانتقال إلى التاب الثاني
      if (newTabIndex === 1 && !member.agree_terms) {
        toast.error("يجب الموافقة على السياسات والأحكام قبل متابعة التسجيل");
        return;
      }
  
      // الانتقال إلى التاب الثالث مع التحقق من الهاتف والبريد
      if (newTabIndex === 2 && (!isPhoneVerified || !isEmailVerified)) {
        toast.error("يجب التحقق من رقم الهاتف والبريد الإلكتروني أولاً");
        return;
      }
  
      // التحقق من الشروط للانتقال إلى التاب الرابع
      if (newTabIndex === 3 && member.role === 'مالك وحدة سكنية') {
        if (!member.developer_company_name || !member.developer_rating || !member.build_quality_rating) {
          toast.error("يرجى إكمال جميع تفاصيل شركة التطوير العقاري");
          return;
        }
      }
    }
  
    // تغيير التاب النشط
    setActiveTab(newTabIndex);
  };
  



  // دالة للتحقق مما إذا كانت الوحدة مسجلة مسبقاً
  const checkUnitRegistration = (floor, unit) => {
    // التأكد من أن جميع القيم موجودة قبل التحقق
    if (member.area_id && member.neighborhood_id && member.letter_id && member.building_number && floor && unit) {
      axios
        .get('https://b-watan.net/api.php?endpoint=checkUnit', {
          params: {
            area_id: member.area_id,
            neighborhood_id: member.neighborhood_id,
            letter_id: member.letter_id,
            building_number: member.building_number,
            floor_number: floor,   // لا تستخدم أي ترميز يدوي، ارسل القيمة مباشرة
            unit_number: unit,     // لا تستخدم أي ترميز يدوي
          },
        })
        .then((response) => {
          if (response.data.exists) {
            // عرض إشعار كبير بأن الوحدة مسجلة بالفعل
            toast.error(`هذه الوحدة مسجلة بالفعل باسم: ${response.data.full_name_ar.split(' ')[0]} (شركة: ${response.data.developer_company_name}) إذا لم تكن أنت ، نأمل مراجعة الشركة العقارية المتعاقد معها`);
          } 
        })
        .catch((error) => {
          toast.error('حدث خطأ أثناء التحقق من الوحدة.');
        });
    }
  };
  
  

  
  const isBirthYearEntered = !!member.birth_year; // التحقق من أن سنة الميلاد قد أدخلت


  return (
    <div className="full-background ">
      <ToastContainer />
      <div className="statistics-page-container-members">
        <div className="page-header">
          <img src="assets/img/logo/logo.png" alt="Logo" className="logo" />
          <h1 className="page-title">تسجيل عضو جديد</h1>
        </div>

        <Tabs selectedIndex={activeTab} onSelect={handleTabSwitch}>
          <TabList>
            <Tab>السياسات والاحكام</Tab>
            <Tab>المعلومات الشخصية</Tab>
            <Tab>معلومات الوحدة أو الأرض الخاصة بك</Tab>
            {member.role === 'مالك وحدة سكنية' && <Tab> شركة التطوير العقاري المتعاقد معها</Tab>}
            <Tab>إرفاق إثبات الملكية </Tab>
          </TabList>

          <TabPanel>
            <div className="terms-container">
              <p>
                للإنضمام معنا في الإتحاد  يجب عليك موافقتك على الشروط والأحكام التالية.
              </p>

              <div className="form-section">
            <div className='w-100 rtl centerP ' style={{ width: '100% !important background-color: red' }}>
                <Policies/>
              </div>

              <div className="d-flex gap-4 flex-row" style={{ padding: '20px' }}>
  <div>
    <div className="d-flex gap-4 flex-row align-items-center">
      <input
        className="radio-input"
        type="radio"
        name="agree_terms"
        checked={member.agree_terms}
        onChange={() => setMember({ ...member, agree_terms: true })}
        style={{ width: '25px', height: '25px', cursor: 'pointer' }}
      />
      <label style={{ fontSize: '20px', fontWeight: 'bold', cursor: 'pointer' }}>
        أوافق على السياسات الخاصة بالإنضمام إلى الإتحاد
      </label>
    </div>

    <div className="d-flex gap-4 justify-content-center align-items-center flex-row mt-3">
      <input
        className="radio-input"
        type="radio"
        name="agree_terms"
        checked={!member.agree_terms}
        onChange={() => setMember({ ...member, agree_terms: false })}
        style={{ width: '25px', height: '25px', cursor: 'pointer' }}
      />
      <label style={{ fontSize: '20px', fontWeight: 'bold', cursor: 'pointer' }}>
        غير موافق على السياسات ولا أريد الإنضمام إلى الإتحاد
      </label>
    </div>
  </div>
</div>


            </div>



            </div>
           



<div className="navigation-buttons container">
  <button
    type="button"
    onClick={() => handleTabSwitch(activeTab - 1)}
    disabled={activeTab === 0} /* تعطيل الزر إذا كنا في أول تاب */
    className="prev-button styled-button"
  >
    السابق
  </button>
  <div>
  {/* زر "التالي" */}
  <button
    type="button"
    onClick={handleNextButtonClick}
    className="next-button w-100 styled-button"
    disabled={!canProceedToNextTab()} // تعطيل الزر بناءً على التحقق من الشروط في التاب الحالي
  >
    التالي <FaArrowLeft />
  </button>

  {/* رسالة التحقق */}
  {!canProceedToNextTab() && (
    <div className="error-message">
      {activeTab === 0 && !member.agree_terms && (
        <p>يجب الموافقة على السياسات والأحكام للمتابعة.</p>
      )}
      {activeTab === 1 && (
        <>
          {!member.full_name_ar && <p>يجب إدخال الاسم الكامل.</p>}
          {!validatePhoneNumber(member.phone_number) && <p>يجب إدخال رقم هاتف صحيح.</p>}
          {!validateEmail(member.email) && <p>يجب إدخال بريد إلكتروني صحيح.</p>}
        </>
      )}
      {activeTab === 2 && (
        <>
          {!member.area_id && <p>يجب اختيار المنطقة.</p>}
          {!member.neighborhood_id && <p>يجب اختيار الحي.</p>}
          {!member.letter_id && <p>يجب اختيار الحرف.</p>}
          {!member.building_number && <p>يجب اختيار رقم العمارة.</p>}
        </>
      )}
      {activeTab === 3 && member.role === 'مالك وحدة سكنية' && (
        <>
          {!member.developer_company_name && <p>يجب إدخال اسم الشركة العقارية.</p>}
          {!member.developer_rating && <p>يجب إدخال تقييم للشركة العقارية.</p>}
          {!member.build_quality_rating && <p>يجب إدخال تقييم جودة البناء.</p>}
        </>
      )}
    </div>
  )}
</div>

</div>



          </TabPanel>








          <TabPanel>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                          {/* Role */}
                          <div className="form-section">
                <label>الصفة</label>
                <Select
                  value={member.role ? { value: member.role, label: member.role } : null}
                  options={[
                    { value: 'مالك وحدة سكنية', label: 'مالك وحدة سكنية' },
                    { value: 'مالك أرض', label: 'مالك أرض' },
                    { value: 'مستثمر', label: 'مستثمر' },
                  ]}
                  onChange={(option) => setMember({ ...member, role: option.value })}
                  placeholder="اختر الصفة"
                  className="select-box glassy"
                  required
                  // isDisabled={!isEmailVerified} // تعطيل الحقل حتى يتم التحقق من البريد الإلكتروني
                />
              </div>
              
              {/* Full Name */}
              <div className="form-section">
                <label>الاسم الكامل</label>
                <input
                  type="text"
                  value={member.full_name_ar}
                  onChange={(e) => {
                    setMember({ ...member, full_name_ar: e.target.value });
                    handleValidation('full_name_ar', e.target.value);
                  }}
                  required
                  isDisabled={!isEmailVerified} // تعطيل الحقل حتى يتم التحقق من البريد الإلكتروني

                  className={`input-text glassy text-white ${errors.full_name_ar ? 'input-error' : ''}`}
                  placeholder="الاسم الكامل"
                />
                {errors.full_name_ar && <span className="error-message">{errors.full_name_ar}</span>}
              </div>

              {/* Birth Year */}
              <div className="form-section d-flex flex-column">
                <label>سنة الميلاد</label>
                <DatePicker
                  selected={member.birth_year ? new Date(member.birth_year, 0, 1) : null}
                  onChange={(date) => setMember({ ...member, birth_year: date.getFullYear() })}
                  showYearPicker
                  dateFormat="yyyy"
                  minDate={new Date(1940, 0, 1)}
                  maxDate={new Date(2024, 11, 31)}
                  className="input-text glassy"
                  placeholderText="اختر سنة الميلاد"
                  disabled={!validateFullName(member.full_name_ar)}
                />
              </div>

              <div>
  {/* حقل رقم الهاتف */}
  <div className="form-section" style={{ marginBottom: '20px' }}>
    <label style={{ fontSize: '16px', fontWeight: 'bold', display: 'block' }}>رقم الهاتف</label>
    <PhoneInput
  country={'eg'}  // الدولة الافتراضية هي مصر
  value={member.phone_number}
  onChange={(phone, country) => handlePhoneNumberChange(phone, country)}  // تمرير رقم الهاتف وكود الدولة
  enableSearch={true}
  preferredCountries={['eg', 'sa', 'ae', 'kw', 'jo', 'lb', 'qa', 'om', 'bh']}
  searchPlaceholder="ابحث بكود الدولة"
  inputStyle={{
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    direction: 'ltr',
  }}
  containerStyle={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}
  placeholder={`مثال: +20 10XXXXXXX إذا كان الرقم مصري`}
/>

  </div>

  {/* حقل رقم الواتساب */}
  <div className="form-section" style={{ marginBottom: '20px' }}>
    <label style={{ fontSize: '16px', fontWeight: 'bold', display: 'block' }}>رقم الواتساب</label>
    <PhoneInput
      country={'eg'}  // الدولة الافتراضية هي مصر
      value={member.whatsapp_number}
      onChange={(phone) => setMember({ ...member, whatsapp_number: phone })}
      enableSearch={true}
      preferredCountries={['eg', 'sa', 'ae', 'kw', 'jo', 'lb', 'qa', 'om', 'bh']}
      searchPlaceholder="ابحث بكود أو اسم الدولة"
      inputStyle={{
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        direction: 'ltr',
      }}
      containerStyle={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}
      placeholder={`مثال: +20 10XXXXXXX`} // Placeholder مخصص بناءً على الدولة
      disabled={!member.birth_year || isPhoneSameAsWhatsapp} // تعطيل الحقل إذا لم يتم إدخال سنة الميلاد أو إذا تم نسخ رقم الهاتف
      onCountryChange={(country) => {
        // تفريغ الحقل عند تغيير الدولة
        setMember({ ...member, whatsapp_number: '' });
        // تحديث placeholder بناءً على كود الدولة المختارة
        document.querySelector('input[name="whatsapp_number"]').placeholder = `مثال: +${country.dialCode} 10XXXXXXXX`;
      }}
    />
  </div>

  {/* خيار نسخ رقم الهاتف إلى الواتساب */}
  <div className="form-section" style={{ marginBottom: '20px', textAlign: 'left' }}>
    <label style={{ fontSize: '16px', fontWeight: 'bold', display: 'inline-block', marginRight: '10px' }}>
      هل نفس رقم الواتساب؟
    </label>
    <input
      type="checkbox"
      checked={isPhoneSameAsWhatsapp}
      onChange={(e) => {
        const isChecked = e.target.checked;
        setIsPhoneSameAsWhatsapp(isChecked);

        if (isChecked) {
          // نسخ رقم الهاتف إلى حقل الواتساب بدون "+"
          const phoneWithoutPlus = member.phone_number.startsWith('+')
            ? member.phone_number.substring(1)
            : member.phone_number;
          setMember({ ...member, whatsapp_number: phoneWithoutPlus });
        } else {
          setMember({ ...member, whatsapp_number: '' });
        }
      }}
      style={{ marginLeft: '10px', cursor: 'pointer' }}
      disabled={!member.birth_year} // تعطيل خيار النسخ إذا لم يتم إدخال سنة الميلاد
    />
  </div>
</div>






              {/* Email */}
              <div className="form-section">
                <label>البريد الإلكتروني</label>
                <input
                  type="email"
                  value={member.email}
                  onChange={handleEmailChange}
                  required
                  className={`input-text glassy ${errors.email ? 'input-error' : ''}`}
                  placeholder="البريد الإلكتروني"
                  disabled={!isPhoneVerified} // تعطيل حقل البريد الإلكتروني حتى يتم التحقق من الهاتف
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>



              {/* رسالة خطأ عند التحقق */}
      
{Object.values(errors).some(error => error) && (
  <div className="error-box" style={{ backgroundColor: 'red', color: 'white', padding: '10px', marginTop: '10px' }}>
    <ul>
      {Object.entries(errors).map(([key, error]) => (
        error && <li key={key}>{error}</li>
      ))}
    </ul>
  </div>
)}


            </form>


            <div className="navigation-buttons container">
  <button
    type="button"
    onClick={() => handleTabSwitch(activeTab - 1)}
    disabled={activeTab === 0}
    className="prev-button styled-button styled-button"
  >
    <FaArrowRight /> السابق
  </button>
  <div>
  {/* زر "التالي" */}
  <button
    type="button"
    onClick={handleNextButtonClick}
    className="next-button w-100 styled-button"
    disabled={!canProceedToNextTab()} // تعطيل الزر بناءً على التحقق من الشروط في التاب الحالي
  >
    التالي <FaArrowLeft />
  </button>

  {/* رسالة التحقق */}
  {!canProceedToNextTab() && (
    <div className="error-message">
      {activeTab === 0 && !member.agree_terms && (
        <p>يجب الموافقة على السياسات والأحكام للمتابعة.</p>
      )}
      {activeTab === 1 && (
        <>
          {!member.full_name_ar && <p>يجب إدخال الاسم الكامل.</p>}
          {!validatePhoneNumber(member.phone_number) && <p>يجب إدخال رقم هاتف صحيح.</p>}
          {!validateEmail(member.email) && <p>يجب إدخال بريد إلكتروني صحيح.</p>}
        </>
      )}
      {activeTab === 2 && (
        <>
          {!member.area_id && <p>يجب اختيار المنطقة.</p>}
          {!member.neighborhood_id && <p>يجب اختيار الحي.</p>}
          {!member.letter_id && <p>يجب اختيار الحرف.</p>}
          {!member.building_number && <p>يجب اختيار رقم العمارة.</p>}
        </>
      )}
      {activeTab === 3 && member.role === 'مالك وحدة سكنية' && (
        <>
          {!member.developer_company_name && <p>يجب إدخال اسم الشركة العقارية.</p>}
          {!member.developer_rating && <p>يجب إدخال تقييم للشركة العقارية.</p>}
          {!member.build_quality_rating && <p>يجب إدخال تقييم جودة البناء.</p>}
        </>
      )}
    </div>
  )}
</div>

</div>



          </TabPanel>

          <TabPanel>
  <div className="form-section">
    <label>اختر المنطقة</label>
    <Select
      value={member.area_id ? { value: member.area_id, label: areas.find(a => a.id === member.area_id)?.name } : null}
      options={areas.map((area) => ({ value: area.id, label: area.name }))}
      onChange={handleAreaChange}
      placeholder="اختر المنطقة"
      className="select-box white-background glassy"
      isDisabled={!isEmailVerified}
    />
  </div>

            <div className="form-section">
              <label>اختر الحي</label>
              <Select
                value={member.neighborhood_id ? { value: member.neighborhood_id, label: neighborhoods.find(n => n.id === member.neighborhood_id)?.name } : null}
                options={neighborhoods.map((neighborhood) => ({
                  value: neighborhood.id,
                  label: neighborhood.name,
                }))}
                onChange={handleNeighborhoodChange}
                placeholder="اختر الحي"
                className="text-black"
                isDisabled={!member.area_id} // تعطيل حتى يتم اختيار المنطقة
              />
            </div>

            <div className="form-section">
              <label>اختر الحرف</label>
              <Select
                value={member.letter_id ? { value: member.letter_id, label: letters.find(l => l.id === member.letter_id)?.letter } : null}
                options={letters.map((letter) => ({ value: letter.id, label: letter.letter }))}
                onChange={handleLetterChange}
                placeholder="اختر الحرف"
                className="text-black"
                isDisabled={!member.neighborhood_id} // تعطيل حتى يتم اختيار الحي
              />
            </div>

            <div className="form-section">
              <label>رقم العمارة</label>
              <Select
                value={member.building_number ? { value: member.building_number, label: member.building_number } : null}
                options={buildings}
                onChange={(option) => setMember({ ...member, building_number: option.value })}
                placeholder="رقم العمارة"
                className="text-black"
                isDisabled={!member.letter_id} // تعطيل حتى يتم اختيار الحرف
              />
            </div>


                    {/* إضافة رقم الطابق */}
                    {member.role === 'مالك وحدة سكنية' && (
  <>
    {/* إضافة رقم الطابق */}
    <div className="form-section">
      <label>رقم الطابق</label>
      <Select
        value={member.floor_number ? { value: member.floor_number, label: member.floor_number } : null}
        options={[
          { value: 'أرضي', label: 'أرضي' },
          { value: 'أرضي مع بيزمينت', label: 'أرضي مع بيزمينت' },
          { value: 'أول علوي', label: 'أول علوي' },
          { value: 'ثاني علوي', label: 'ثاني علوي' },
          { value: 'ثالث علوي', label: 'ثالث علوي' },
          { value: 'ثالث مع روف', label: 'ثالث مع روف' },
          { value: 'روف', label: 'روف' },
        ]}
        onChange={(option) => {
          setMember({ ...member, floor_number: option.value });
          
          // تحقق فوري بعد اختيار الطابق باستخدام `checkUnitRegistration`
          checkUnitRegistration(option.value, member.unit_number); 
        }}
        placeholder="اختر رقم الطابق"
        className="glassy"
        isDisabled={!member.building_number} // تعطيل حتى يتم اختيار رقم العمارة
      />
    </div>

    {/* إضافة رقم الشقة */}
    <div className="form-section">
      <label>رقم الشقة</label>
      <input
        type="text"
        value={member.unit_number || ''}
        onChange={(e) => {
          setMember({ ...member, unit_number: e.target.value });
          checkUnitRegistration(member.floor_number, e.target.value); // التحقق بعد إدخال رقم الشقة
        }}
        className="input-text glassy text-white"
        placeholder="أدخل رقم الشقة"
        disabled={!member.building_number}
      />
    </div>
  </>
)}









            <div className="form-section">
              <label>هل تسكن حالياً؟</label>
              <Select
                value={{ value: member.is_resident, label: member.is_resident }}
                options={[
                  { value: 'نعم', label: 'نعم' },
                  { value: 'لا', label: 'لا' },
                ]}
                onChange={handleResidentChange}
                className="text-black"
              />
            </div>

            {showMoveYear && (
              <div className="form-section d-flex flex-column gap-3">
                <label>
                  {member.is_resident === 'نعم' ? 'متى سكنت بالمنطقة؟' : 'العام المتوقع للسكن'}
                </label>
                <DatePicker
                  selected={member.intended_move_year ? new Date(member.intended_move_year, 0, 1) : null}
                  onChange={(date) => setMember({ ...member, intended_move_year: date.getFullYear() })}
                  showYearPicker
                  dateFormat="yyyy"
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date(2045, 11, 31)}
                  className="input-text glassy"
                  placeholderText={member.is_resident === 'نعم' ? 'اختر السنة' : 'اختر العام المتوقع للسكن'}
                />
              </div>
            )}

<div className="navigation-buttons container">
  <button
    type="button"
    onClick={() => handleTabSwitch(activeTab - 1)}
    disabled={activeTab === 0} /* تعطيل الزر إذا كنا في أول تاب */
    className="prev-button styled-button"
  >
    السابق
  </button>
  <div>
  {/* زر "التالي" */}
  <button
    type="button"
    onClick={handleNextButtonClick}
    className="next-button w-100 styled-button"
    disabled={!canProceedToNextTab()} // تعطيل الزر بناءً على التحقق من الشروط في التاب الحالي
  >
    التالي <FaArrowLeft />
  </button>

  {/* رسالة التحقق */}
  {!canProceedToNextTab() && (
    <div className="error-message">
      {activeTab === 0 && !member.agree_terms && (
        <p>يجب الموافقة على السياسات والأحكام للمتابعة.</p>
      )}
      {activeTab === 1 && (
        <>
          {!member.full_name_ar && <p>يجب إدخال الاسم الكامل.</p>}
          {!validatePhoneNumber(member.phone_number) && <p>يجب إدخال رقم هاتف صحيح.</p>}
          {!validateEmail(member.email) && <p>يجب إدخال بريد إلكتروني صحيح.</p>}
        </>
      )}
      {activeTab === 2 && (
        <>
          {!member.area_id && <p>يجب اختيار المنطقة.</p>}
          {!member.neighborhood_id && <p>يجب اختيار الحي.</p>}
          {!member.letter_id && <p>يجب اختيار الحرف.</p>}
          {!member.building_number && <p>يجب اختيار رقم العمارة.</p>}
        </>
      )}
      {activeTab === 3 && member.role === 'مالك وحدة سكنية' && (
        <>
          {!member.developer_company_name && <p>يجب إدخال اسم الشركة العقارية.</p>}
          {!member.developer_rating && <p>يجب إدخال تقييم للشركة العقارية.</p>}
          {!member.build_quality_rating && <p>يجب إدخال تقييم جودة البناء.</p>}
        </>
      )}
    </div>
  )}
</div>

</div>



          </TabPanel>



          {member.role === 'مالك وحدة سكنية' && (
            <TabPanel>
              <div className="form-section ">
                <label>اسم الشركة العقارية</label>
                <input
                  type="text"
                  value={member.developer_company_name}
                  onChange={(e) => setMember({ ...member, developer_company_name: e.target.value })}
                  className="input-text glassy text-white"
                  placeholder="اسم الشركة العقارية"
                />
              </div>

              {/* تقييم الشركة */}
              <div className="form-section d-flex flex-column gap-3 justify-content-center align-items-center">
                <label>تقييم الشركة العقارية</label>
                <StarRatings
                  rating={member.developer_rating}
                  starRatedColor="gold"
                  changeRating={(newRating) => setMember({ ...member, developer_rating: newRating })}
                  numberOfStars={5}
                  name="developerRating"
                />
              </div>

              {/* تقييم جودة البناء */}
              <div className="form-section d-flex flex-column gap-3 justify-content-center align-items-center">
                <label>تقييم جودة البناء</label>
                <StarRatings
                  rating={member.build_quality_rating}
                  starRatedColor="gold"
                  changeRating={(newRating) => setMember({ ...member, build_quality_rating: newRating })}
                  numberOfStars={5}
                  name="buildQualityRating"
                />
              </div>

              {/* تقييم جودة التشطيبات */}
              <div className="form-section d-flex flex-column gap-3 justify-content-center align-items-center siz">
                <label>تقييم جودة التشطيبات</label>
                <StarRatings
                  rating={member.finish_quality_rating}
                  starRatedColor="gold"
                  changeRating={(newRating) => setMember({ ...member, finish_quality_rating: newRating })}
                  numberOfStars={5}
                  name="finishQualityRating"
                />
              </div>

              {/* تسليم في الموعد المحدد */}
              <div className="form-section">
                <label>تم التسليم في الموعد المحدد؟</label>
                <Select
                  value={{ value: member.on_time_delivery, label: member.on_time_delivery }}
                  options={[
                    { value: 'نعم', label: 'نعم' },
                    { value: 'لا', label: 'لا' },
                  ]}
                  onChange={(option) => setMember({ ...member, on_time_delivery: option.value })}
                  className="text-black"
                />
              </div>

              {/* التعاون مع الشركة */}
              <div className="form-section">
                <label>تعاون الشركة مع المالك</label>
                <Select
                  value={{ value: member.company_collaboration, label: member.company_collaboration }}
                  options={[
                    { value: 'نعم', label: 'نعم' },
                    { value: 'لا', label: 'لا' },
                  ]}
                  onChange={(option) => setMember({ ...member, company_collaboration: option.value })}
                  className="text-black"
                />
              </div>

              <div className="form-section">
  <label>هل لاحظت مخالفات؟</label>
  <Select
    value={{ value: member.noticed_violations, label: member.noticed_violations }}
    options={[
      { value: 'نعم', label: 'نعم' },
      { value: 'لا', label: 'لا' },
    ]}
    onChange={(option) => setMember({ ...member, noticed_violations: option.value })}
    className="text-black"
  />
</div>


{member.noticed_violations === 'نعم' && (
  <div className="form-section">
    <label>تفاصيل المخالفات</label>
    <textarea
      value={member.violation_details}
      onChange={(e) => setMember({ ...member, violation_details: e.target.value })}
      className="input-text glassy text-white"
      placeholder="اكتب تفاصيل المخالفات إن وجدت"
    />
  </div>
)}


<div className="navigation-buttons container">
  <button
    type="button"
    onClick={() => handleTabSwitch(activeTab - 1)}
    disabled={activeTab === 0} /* تعطيل الزر إذا كنا في أول تاب */
    className="prev-button styled-button"
  >
    السابق
  </button>
  <div>
  {/* زر "التالي" */}
  <button
    type="button"
    onClick={handleNextButtonClick}
    className="next-button w-100 styled-button"
    disabled={!canProceedToNextTab()} // تعطيل الزر بناءً على التحقق من الشروط في التاب الحالي
  >
    التالي <FaArrowLeft />
  </button>

  {/* رسالة التحقق */}
  {!canProceedToNextTab() && (
    <div className="error-message">
      {activeTab === 0 && !member.agree_terms && (
        <p>يجب الموافقة على السياسات والأحكام للمتابعة.</p>
      )}
      {activeTab === 1 && (
        <>
          {!member.full_name_ar && <p>يجب إدخال الاسم الكامل.</p>}
          {!validatePhoneNumber(member.phone_number) && <p>يجب إدخال رقم هاتف صحيح.</p>}
          {!validateEmail(member.email) && <p>يجب إدخال بريد إلكتروني صحيح.</p>}
        </>
      )}
      {activeTab === 2 && (
        <>
          {!member.area_id && <p>يجب اختيار المنطقة.</p>}
          {!member.neighborhood_id && <p>يجب اختيار الحي.</p>}
          {!member.letter_id && <p>يجب اختيار الحرف.</p>}
          {!member.building_number && <p>يجب اختيار رقم العمارة.</p>}
        </>
      )}
      {activeTab === 3 && member.role === 'مالك وحدة سكنية' && (
        <>
          {!member.developer_company_name && <p>يجب إدخال اسم الشركة العقارية.</p>}
          {!member.developer_rating && <p>يجب إدخال تقييم للشركة العقارية.</p>}
          {!member.build_quality_rating && <p>يجب إدخال تقييم جودة البناء.</p>}
        </>
      )}
    </div>
  )}
</div>

</div>



            </TabPanel>
          )}

<TabPanel>
  <div className="form-section">
    {/* تعديل النص بناءً على الصفة */}
    <label>
      {member.role === 'مالك أرض'
        ? 'إرفاق إخطار التخصيص موضح به اسم المالك وبيانات القطعة'
        : 'إرفاق ما يثبت الملكية موضح بها الاسم والتليفون وبيانات الوحدة المتعاقد عليها'}
    </label>
    <input
      type="file"
      onChange={handleFileChange}
      className="input-text glassy"
      disabled={!member.intended_move_year && !member.is_resident}
      // multiple // السماح بإرفاق عدة ملفات
    />
    {errors.contract_file && <span className="error-message">{errors.contract_file}</span>}
    {filePreview && (
      <div className="file-preview" onClick={() => setPreviewVisible(!previewVisible)}>
        <img
          src={filePreview}
          alt="Contract Preview"
          style={{
            width: previewVisible ? '100%' : '150px',
            height: previewVisible ? 'auto' : '150px',
            cursor: 'pointer',
          }}
        />
      </div>
    )}
  </div>

  <div className="form-section">
    <div className="d-flex gap-4 flex-row align-items-center">
      <input
        className="radio-input"
        type="radio"
        name="agree_terms"
        checked={member.agree_terms}
        onChange={() => setMember({ ...member, agree_terms: true })}
        style={{ width: '25px', height: '25px', cursor: 'pointer' }}
      />
      <label style={{ fontSize: '20px', fontWeight: 'bold', cursor: 'pointer' }}>
        أوافق على السياسات الخاصة بالإنضمام إلى الإتحاد
      </label>
    </div>
  </div>

  {/* زر الإرسال */}
  <button
    type="submit"
    onClick={handleSubmit}
    className="submit-button w-100"
    disabled={!isPhoneVerified || !isEmailVerified || isSubmitting}
  >
    {isSubmitting ? (
      <span className="spinner"></span>
    ) : (
      'إرسال'
    )}
  </button>

  {isSubmitting && (
    <div className="confirmation-layer">
      <p>جار التسجيل... من فضلك انتظر.</p>
    </div>
  )}
</TabPanel>



        </Tabs>

        {/* Modal للتأكيد */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Success Modal"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="modal-content d-flex justify-content-center align-items-center">
            <h2>تم التسجيل بنجاح!</h2>
            <p>شكراً لانضمامك إلى الاتحاد. تم تسجيلك بنجاح.</p>
            <button onClick={closeModal} className="modal-close-button">إغلاق</button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MemberRegistration;
