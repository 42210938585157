import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

const ApexChart = () => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [buildCounts, setBuildCounts] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const neighborhoodResponse = await axios.get('https://b-watan.net/app.php?endpoint=getNeighborhoodProgress');

        const neighborhoods = neighborhoodResponse.data;

        // استخراج نسبة البناء لكل حي
        const percentages = neighborhoods.map(neighborhood => parseFloat(neighborhood.build_percentage).toFixed(2));

        // استخراج أسماء الأحياء
        const neighborhoodNames = neighborhoods.map(neighborhood => neighborhood.neighborhood);

        // استخراج عدد العمارات المبنية لكل حي
        const builtCounts = neighborhoods.map(neighborhood => neighborhood.built_buildings || 0);

        // استخراج إجمالي العمارات لكل حي
        const totalCounts = neighborhoods.map(neighborhood => neighborhood.total_buildings || 0);

        setSeries(percentages);
        setCategories(neighborhoodNames);
        setBuildCounts(builtCounts);
        setTotalCounts(totalCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const options = {
    chart: {
      type: 'polarArea',
      width: '100%', // التوافق مع جميع الشاشات
    },
    labels: categories, // أسماء الأحياء
    stroke: {
      colors: ['#fff'], // الحدود البيضاء
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      custom: function({ series, seriesIndex, w }) {
        const neighborhoodName = w.globals.labels[seriesIndex];
        const totalBuildings = totalCounts[seriesIndex];
        const builtBuildings = buildCounts[seriesIndex];
        const buildPercentage = series[seriesIndex];

        return `
          <div style="padding:10px; background-color: #333; color: #fff; border-radius: 5px; font-family: 'Beiruti', sans-serif;">
            <strong>الحي: ${neighborhoodName}</strong><br/>
            إجمالي العمارات: ${totalBuildings}<br/>
            العمارات المبنية: ${builtBuildings}<br/>
            نسبة البناء: ${buildPercentage}%
          </div>
        `;
      }
    },
    dataLabels: {
      enabled: false, // إخفاء النصوص أعلى الشرائح
      style: {
        fontSize: '16px',
        colors: ['#000'], // لون النصوص
        fontFamily: 'Beiruti, sans-serif',
      },
    },
    legend: {
      labels: {
        colors: ['#fff'], // لون النصوص في القائمة باللون الأبيض
        fontFamily: 'Beiruti, sans-serif',
      },
    },
    title: {
      text: 'نسب البناء لجميع الأحياء ومعلومات العمارات المبنية',
      align: 'center',
      style: {
        fontSize: '22px',
        color: '#fff',
        fontFamily: 'Beiruti, sans-serif',
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            width: '100%', // التوافق مع شاشات التابلت
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%', // تكبير الرسم البياني للشاشات الصغيرة
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            formatter: function(seriesName, opts) {
              return `<div style="white-space: nowrap; text-align: center;">${seriesName}</div>`; // عرض الأحياء تحت بعضها
            },
          },
        },
      },
    ],
  };

  if (loading) {
    return <p style={{ color: '#fff', fontFamily: 'Beiruti, sans-serif' }}>Loading...</p>;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div id="chart" style={{ width: '100%', maxWidth: '600px' }}>
        <ReactApexChart options={options} series={series} type="polarArea" />
      </div>
    </div>
  );
};

export default ApexChart;
