import { useState } from 'react';
import { FaFacebookF, FaTwitter, FaPinterestP, FaPhoneAlt, FaEnvelope, FaSearch, FaLinkedinIn, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeOneHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
<header className="rtl" dir="rtl">
   <div className="header__area p-relative header__transparent rtl">
      <div className="header__top d-none d-md-block">



      </div>
      <div id="header__sticky" className={stickyMenu ? " header__bottom  " : "header__bottom"}>
         <div className="container ">
            <div className="row align-items-center ">
               <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo">
                     <NavLink to="/">
                        <img src="assets/img/logo/logo.png" alt="logo"/>
                     </NavLink>
                  </div>
                  <div className="logo-gradient">
                     <NavLink to="/">
                        <img src="assets/img/logo/logo-gradient.png" alt="logo"/>
                     </NavLink>
                  </div>
               </div>
               <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
               <div className="container">
  <div className="row align-items-center">
    <div className="col-12 d-flex justify-content-end">
      <div className="header__social d-flex">
        <ul className="list-unstyled d-flex">
          <li className="mx-2">
            <a href="https://www.facebook.com/BWatanNewCairoCommunity"><i><FaFacebookF /></i></a>
          </li>
          <li className="mx-2">
            <a href="#"><i><FaYoutube /></i></a>
          </li>
          <li className="mx-2">
            <a href="https://chat.whatsapp.com/HbiuGNMAltxBI5ruU8FDf0?fbclid=IwY2xjawFNcp9leHRuA2FlbQIxMAABHdJQHG0-Uu_e9fqGv6kLdphfucySCs0vRbEJ6vMj8eFpVq5hvV_jQvqbnA_aem_WVyHHWVVV5bjD2KahWTLNw"><i><FaWhatsapp /></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

               </div>
            </div>
         </div>
      </div>
      <div className="header__search-wrapper">
         <div className="container">
            <div className="row">
               <div className="col-xl-12">
                  <form action="#">
                     <input type="text" placeholder="كلماتك المفتاحية" />
                     <button type="button"><i> <FaSearch/> </i></button>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <div className="body-overlay-2"></div>
   </div>
</header>




         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

export default HomeOneHeader;