import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi'; // استيراد أيقونة الإغلاق

const Modal = ({ isOpen, closeModal, children }) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isOpen) setIsClosing(false);
  }, [isOpen]);

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-background') {
      triggerClose();
    }
  };

  const triggerClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
    }, 500); // مدة تأثير الغلق
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div
      id="modal-background"
      onClick={handleOutsideClick}
      className={`modal-background ${isClosing ? 'closing' : ''}`}
    >
      <div className={`modal-content ${isClosing ? 'closing' : ''}`}>
        {/* زر إغلاق الـ modal */}
        <button className="modal-close-btn" onClick={triggerClose}>
          <FiX />
        </button>

        {/* المحتوى المعروض داخل الـ modal */}
        <div className="modal-inner-content">
          {children}
        </div>
      </div>

      {/* ستايل الـ modal */}
      <style jsx>{`
        .modal-background {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5); /* خلفية شفافة للطبقة الخلفية */
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          animation: fadeIn 0.5s ease-in-out;
        }

        .modal-background.closing {
          animation: fadeOut 0.5s ease-in-out forwards;
        }

        .modal-content {
          background: rgba(255, 255, 255, 0.2); /* جعل خلفية الـ modal شفافة */
          padding: 10px;
          border-radius: 20px;
          position: relative;
          max-width: 80vw;
          width: 100%;
          max-height: 90vh;
          display: flex;
          flex-direction: column;
          backdrop-filter: blur(20px); /* تأثير ضبابي */
          border: 1px solid rgba(255, 255, 255, 0.15); /* حدود شفافة */
          transition: all 0.3s ease;
          animation: bounceIn 0.7s ease-out;
          overflow: hidden;
        }

        .modal-content.closing {
          animation: scaleDown 0.5s ease-in-out forwards;
        }

        .modal-inner-content {
          height: 100%;
          padding-right: 15px;
          overflow-y: auto; /* تمكين التمرير العمودي */
        }

        /* تخصيص شريط التمرير */
        .modal-inner-content::-webkit-scrollbar {
          width: 8px; /* عرض شريط التمرير */
        }

        .modal-inner-content::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1); /* لون خلفية شريط التمرير */
        }

        .modal-inner-content::-webkit-scrollbar-thumb {
          background-color: #ff4c4c; /* لون شريط التمرير */
          border-radius: 10px; /* جعل حواف شريط التمرير مستديرة */
          border: 2px solid rgba(255, 255, 255, 0.3); /* إضافة حدود شفافة لشريط التمرير */
        }

        .modal-inner-content::-webkit-scrollbar-thumb:hover {
          background-color: #ff1a1a; /* لون شريط التمرير عند التمرير عليه */
        }

        .modal-close-btn {
          position: absolute;
          top: 15px;
          right: 15px;
          background: linear-gradient(135deg, #ff4c4c, #ff1a1a); /* لون مميز لزر الإغلاق */
          border: none;
          border-radius: 50%; /* زر دائري */
          cursor: pointer;
          font-size: 24px;
          color: #ffffff;
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s, transform 0.3s;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* ظل خفيف لزر الإغلاق */
        }

        .modal-close-btn:hover {
          background-color: #ff0000; /* تغيير لون الخلفية عند التمرير */
          transform: scale(1.1) rotate(45deg); /* تكبير الزر مع دوران */
        }

        @media (max-width: 768px) {
          .modal-content {
            max-width: 95vw;
          }
        }

        @keyframes bounceIn {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          60% {
            opacity: 1;
            transform: scale(1.1);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes scaleDown {
          0% {
            opacity: 1;
            transform: scale(1);
          }
          100% {
            opacity: 0;
            transform: scale(0.8);
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default Modal;
