import React from 'react'

const Policies = () => {
  return (
    <div className="form-section" style={{ width: '100% !important '  }}>
      <label style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold', textAlign: 'right', display: 'block' }}>
        الشروط والأحكام
      </label>
      <div className="about__list rtl text-white d-flex justify-content-center">
        <ul className='d-flex justify-content-center align-items-center flex-column' style={{ listStyleType: 'none', padding: '0', margin: '0' ,  width:"100%" , direction:"rtl" , textAlign: 'right'}}>,
 
          {[
            "يُمنع منعًا باتًا التطرق إلى النواحي السياسية للبلاد، أو مناقشة الأمور الدينية بأي شكل من الأشكال، أو التطرق إلى المسائل الشخصية للأعضاء داخل أي من منصات الاتحاد أو خلال الاجتماعات والمناقشات. هذه المواضيع خارجة تمامًا عن نطاق أهداف الاتحاد ولن يتم التسامح مع أي خرق لهذا البند، ويؤدي تكرار المخالفة إلى اتخاذ إجراءات صارمة بحق العضو المخالف، وذلك حفاظًا على بيئة نقاش محترمة ومهنية تركز على تطوير المنطقة وتحقيق أهداف الاتحاد.",
            "أتعهد بأنني من مالكي الوحدات بالامتداد الشرقي، وأقر بالالتزام بإخطار إدارة الاتحاد في حال التصرف في الوحدة بالبيع أو التنازل عنها، وذلك للتأكيد على خروجي من الاتحاد وإتاحة المجال للمالك الجديد للتسجيل كعضو في الاتحاد. يُعتبر هذا الإخطار إلزاميًا لضمان استمرارية النظام الإداري وضمان حقوق المالكين الجدد في التمتع بخدمات الاتحاد",
            "الالتزام بالمشاركة في جميع المبادرات التي تسعى إلى تشكيل الهوية العامة للمنطقة وتطويرها بشكل مستدام.",
            "تشجيع تقديم الأفكار والمقترحات التي تسهم في وضع أسس متينة لمجتمع راقٍ ومسؤول.",
            "ضرورة الحفاظ على بيئة تواصل محترمة وبناءة بين جميع الأعضاء في جميع المنصات المتاحة.",
            "يُحظر نشر أي مواد غير متعلقة بالمناقشات الخاصة بتطوير المنطقة، سواء على وسائل التواصل أو منصات النقاش.",
            "العمل بروح الفريق والتعاون مع الأعضاء لوضع رؤية مشتركة تخدم مصالح المجتمع المستقبلي.",
            "الالتزام بعدم نشر الإعلانات أو الترويج لأي نشاطات غير متعلقة بتطوير المنطقة دون إذن مسبق وفي المكان المخصص لهذا.",
            "احترام الآراء المختلفة والعمل على إيجاد حلول توافقية تضمن تحقيق الأهداف المشتركة.",
            "يُمنع تمامًا استخدام المنصات الخاصة بالاتحاد لأغراض شخصية أو خارج نطاق مهام الاتحاد.",
            "الالتزام بإرشادات اللجنة التنسيقية لضمان توجيه الجهود المشتركة بشكل فعال نحو تحقيق الأهداف الموضوعة.",
            "الالتزام بالمشاركة الفعالة مع الفريق في جميع منصات التواصل الاجتماعي الخاصة بالاتحاد لتبادل المعلومات والأفكار.",
            "يجب على جميع الأعضاء تقديم مقترحاتهم بانتظام لتحسين المنطقة وتطويرها بما يتماشى مع رؤية الاتحاد.",
            "يُطلب من الأعضاء التفاعل مع استفسارات وآراء الآخرين بروح من التعاون والاحترام المتبادل."
          ].map((policy, index) => (
            <li className='d-flex justify-content-center align-items-center flex-column ' key={index} style={{ 
                color: '#fff', 
                border: '1px solid rgba(255, 255, 255, 0.2)', 
                padding: '15px', 
                marginBottom: '15px', 
                borderRadius: '5px', 
                position: 'relative', 
                transition: 'all 0.3s ease', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width:"100%",

              }}
              onMouseEnter={(e) => e.currentTarget.style.borderColor = 'rgba(255, 255, 255, 0.6)'}
              onMouseLeave={(e) => e.currentTarget.style.borderColor = 'rgba(255, 255, 255, 0.2)'}
            >
              <span className='d-flex justify-content-center align-items-center' style={{ 
                float: 'left', 
                marginRight: '10px', 
                fontSize: '10px',
                marginLeft: '10px',
                color: '#fff !important',
                width:"100%",
              }}><i>✓</i></span>
              <span>{policy}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Policies
