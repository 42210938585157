import React, { useEffect, useState } from 'react'
import AdminDashboard from './membersAdmin'
import LoginAdmin from './Login'

const Admin = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setIsAuthenticated(true);
      }
    }, []);
  
    const handleLoginSuccess = () => {
      setIsAuthenticated(true);
    }
  
 

  return (
    <div>
    {isAuthenticated ? (
      <AdminDashboard />
    ) : (
      <LoginAdmin onLoginSuccess={handleLoginSuccess} />
    )}
  </div>

  )
}

export default Admin
