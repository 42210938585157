import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Alert } from 'react-bootstrap';

const LoginAdmin = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://b-watan.net/users.php', {
        action: 'login',
        username,
        password,
      });

      if (response.data.success) {
        // تخزين الرمز في localStorage
        localStorage.setItem('authToken', response.data.token);
        // استدعاء دالة النجاح
        onLoginSuccess();
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('حدث خطأ أثناء تسجيل الدخول.');
    }
  };

  return (
    <div className="bg">
      <Container className="login-container d-flex align-items-center justify-content-center">
        <div className="login-box d-flex justify-content-center align-items-center flex-column">
          <img src="assets/img/logo/logo-dark.png" alt="Logo" className="logo" />
          <h2 className="text-center mb-4">تسجيل الدخول</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleLogin} dir="rtl" className="w-100"> {/* استخدام خاصية RTL هنا */}
            <Form.Group controlId="username">
              <Form.Label>اسم المستخدم</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل اسم المستخدم"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="input-field"
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>كلمة المرور</Form.Label>
              <Form.Control
                type="password"
                placeholder="أدخل كلمة المرور"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input-field"
              />
            </Form.Group>

            <Button variant="primary" type="submit" block className="submit-btn mt-4 w-100">
              تسجيل الدخول
            </Button>
          </Form>
        </div>
      </Container>

      <style>{`
        @import url('https://fonts.googleapis.com/css2?family=Beiruti:wght@400&display=swap');

        .bg {
          min-height: 100vh;
          background: linear-gradient(180deg, rgba(70, 14, 135, 0.9) 0%, rgba(15, 8, 87, 0.9) 100%);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
        }

        .login-container {
          max-width: 800px;
          width: 100%;
        }

        .login-box {
          background: rgba(255, 255, 255, 0.95);
          padding: 60px;
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 600px;
          transition: transform 0.3s ease;
        }

        .login-box:hover {
          transform: translateY(-10px);
        }

        .logo {
          width: 100px;
          height: auto;
          margin-bottom: 20px;
        }

        h2 {
          font-size: 32px;
          font-weight: bold;
          color: #333;
          font-family: 'Beiruti', sans-serif;
          margin-bottom: 30px;
        }

        .btn-primary {
          background-color: #FF7043;
          border: none;
          font-family: 'Beiruti', sans-serif;
          padding: 15px;
          font-size: 18px;
          transition: background-color 0.3s ease;
        }

        .btn-primary:hover {
          background-color: #e65b32;
        }

        .input-field {
          border-radius: 10px;
          padding: 15px;
          font-size: 16px;
          font-family: 'Beiruti', sans-serif;
          border: 2px solid #1E88E5;
          transition: border-color 0.3s ease;
        }

        .input-field:focus {
          border-color: #FF7043;
          box-shadow: 0 0 5px rgba(255, 112, 67, 0.5);
        }

        @media (max-width: 768px) {
          .login-box {
            padding: 40px;
          }

          h2 {
            font-size: 28px;
          }

          .btn-primary {
            font-size: 16px;
            padding: 12px;
          }

          .input-field {
            padding: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default LoginAdmin;
