import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { Container, Row, Col, Form, Button, InputGroup, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DescriptionAdmin = () => {
  const [entityType, setEntityType] = useState('area');
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [description, setDescription] = useState('');
  const [advantage, setAdvantage] = useState('');
  const [disadvantage, setDisadvantage] = useState('');
  const [media, setMedia] = useState([]);
  const [entityDetails, setEntityDetails] = useState([]);
  const [advantages, setAdvantages] = useState([]);
  const [disadvantages, setDisadvantages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleFileChange = (e) => {
    setMedia([...media, ...e.target.files]);
  };

  const removeMedia = (index) => {
    setMedia(media.filter((_, i) => i !== index));
  };

  // Fetch data based on the selected entity type
  const fetchEntities = useCallback(async (type) => {
    let url;
    switch (type) {
      case 'area':
        url = 'https://b-watan.net/description.php?action=getAreas';
        break;
      case 'neighborhood':
        url = 'https://b-watan.net/description.php?action=getNeighborhoods';
        break;
      default:
        return;
    }
    const response = await axios.get(url);
    setEntities(response.data);
  }, []);

  useEffect(() => {
    fetchEntities(entityType);
  }, [entityType, fetchEntities]);

  // Handle saving data (add or update)
  const handleSave = async () => {
    const formData = new FormData();
    formData.append('entity_type', entityType);
    formData.append('entity_id', selectedEntity);
    formData.append('description', description);
    formData.append('advantages', JSON.stringify(advantages));
    formData.append('disadvantages', JSON.stringify(disadvantages));
    media.forEach((file) => formData.append('media[]', file));

    try {
      const response = await axios.post('https://b-watan.net/description.php?action=createDescription', formData);
      if (response.data.success) {
        alert('تم حفظ البيانات بنجاح');
        fetchEntityDetails(); // Fetch updated data
      } else {
        alert('حدث خطأ أثناء الحفظ: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      alert('حدث خطأ أثناء الحفظ.');
    }
  };

  // Fetch existing entity details
  const fetchEntityDetails = async () => {
    try {
      const response = await axios.get('https://b-watan.net/description.php?action=getAllDescriptions');
      if (Array.isArray(response.data)) {
        setEntityDetails(response.data);
      } else {
        console.error('البيانات المستلمة ليست مصفوفة:', response.data);
        setEntityDetails([]);
      }
    } catch (error) {
      console.error('Error fetching entity details:', error);
    }
  };

  useEffect(() => {
    fetchEntityDetails();
  }, []);

  // Handle deleting an entity detail
  const handleDelete = async (id) => {
    try {
      const response = await axios.post('https://b-watan.net/description.php?action=deleteDescription', { id });
      if (response.data.success) {
        alert('تم الحذف بنجاح');
        fetchEntityDetails(); // Fetch updated data
      } else {
        alert('حدث خطأ أثناء الحذف: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  // Add new advantage or disadvantage
  const addAdvantage = () => {
    if (advantage.trim()) {
      setAdvantages([...advantages, advantage]);
      setAdvantage('');
    }
  };

  const addDisadvantage = () => {
    if (disadvantage.trim()) {
      setDisadvantages([...disadvantages, disadvantage]);
      setDisadvantage('');
    }
  };

  // Remove advantage or disadvantage
  const removeAdvantage = (index) => {
    setAdvantages(advantages.filter((_, i) => i !== index));
  };

  const removeDisadvantage = (index) => {
    setDisadvantages(disadvantages.filter((_, i) => i !== index));
  };

  // Filter entity details based on search query
  const filteredDetails = Array.isArray(entityDetails)
    ? entityDetails.filter((detail) =>
        detail.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">إدارة الوصف والمميزات والعيوب</h1>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>اختر نوع الكيان</Form.Label>
            <Select
              options={[
                { value: 'area', label: 'منطقة' },
                { value: 'neighborhood', label: 'حي' }
              ]}
              onChange={(option) => setEntityType(option.value)}
              placeholder="اختر نوع الكيان"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>اختر {entityType === 'area' ? 'المنطقة' : 'الحي'}</Form.Label>
            <Select
              options={entities.map((entity) => ({ value: entity.id, label: entity.name }))}
              onChange={(option) => setSelectedEntity(option.value)}
              placeholder={`اختر ${entityType === 'area' ? 'المنطقة' : 'الحي'}`}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>الوصف</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{ color: 'black' }} // لون النص أسود
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label>المميزات</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="text"
              value={advantage}
              onChange={(e) => setAdvantage(e.target.value)}
              style={{ color: 'black' }} // لون النص أسود
            />
            <Button variant="primary" onClick={addAdvantage}>
              <FaPlus /> إضافة ميزة
            </Button>
          </InputGroup>

          {/* عرض المميزات المدخلة */}
          {advantages.map((adv, index) => (
            <div key={index} className="d-flex justify-content-between">
              <p>{adv}</p>
              <Button variant="danger" onClick={() => removeAdvantage(index)}>
                <FaTrash />
              </Button>
            </div>
          ))}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Label>العيوب</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="text"
              value={disadvantage}
              onChange={(e) => setDisadvantage(e.target.value)}
              style={{ color: 'black' }} // لون النص أسود
            />
            <Button variant="primary" onClick={addDisadvantage}>
              <FaPlus /> إضافة عيب
            </Button>
          </InputGroup>

          {/* عرض العيوب المدخلة */}
          {disadvantages.map((dis, index) => (
            <div key={index} className="d-flex justify-content-between">
              <p>{dis}</p>
              <Button variant="danger" onClick={() => removeDisadvantage(index)}>
                <FaTrash />
              </Button>
            </div>
          ))}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>تحميل الوسائط (صور / فيديوهات)</Form.Label>
            <Form.Control type="file" multiple onChange={handleFileChange} />
          </Form.Group>

          {/* عرض الملفات المحملة */}
          {media.length > 0 && (
            <div className="mb-3">
              {media.map((file, index) => (
                <div key={index} className="d-flex justify-content-between">
                  <p>{file.name}</p>
                  <Button variant="danger" onClick={() => removeMedia(index)}>
                    <FaTrash />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Button variant="success" className="w-100" onClick={handleSave}>
        حفظ البيانات
      </Button>

      <hr />

      <Form.Control
        type="text"
        placeholder="ابحث عن الوصف"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="mt-4 mb-4"
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>الكيان</th>
            <th>الوصف</th>
            <th>المميزات</th>
            <th>العيوب</th>
            <th>وسائط</th>
            <th>تحكم</th>
          </tr>
        </thead>
        <tbody>
          {filteredDetails.map((detail) => (
            <tr key={detail.id}>
              <td>{detail.entity_name}</td>
              <td>{detail.description}</td>
              <td>{JSON.parse(detail.advantages || '[]').join(', ')}</td>
              <td>{JSON.parse(detail.disadvantages || '[]').join(', ')}</td>
              <td>
                {detail.media ? <a href={detail.media} target="_blank" rel="noopener noreferrer">عرض</a> : 'لا يوجد'}
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDelete(detail.id)}>
                  <FaTrash />
                </Button>
                <Button variant="warning" onClick={() => {
                  setSelectedEntity(detail.id);
                  setDescription(detail.description);
                  setAdvantages(JSON.parse(detail.advantages || '[]'));
                  setDisadvantages(JSON.parse(detail.disadvantages || '[]'));
                }}>
                  <FaEdit />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DescriptionAdmin;
