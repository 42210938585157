import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { FiPlay } from 'react-icons/fi';
import Modal from './Modal'; // استيراد Modal component
import VideoPopup from '../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../hooks/useGlobalContext';
import './HomeHeroSlider.module.css';

const BASE_URL = 'https://b-watan.net/'; // رابط الـ API

const HomeHeroArea = () => {
  const { setIsVideoOpen } = useGlobalContext();
  const [homeSliderData, setHomeSliderData] = useState([]);
  const [videoId, setVideoId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // حالة التحكم في عرض وإغلاق الـ modal
  const [modalContent, setModalContent] = useState(''); // محتوى الـ modal

  // جلب بيانات السلايدر من الـ API
  useEffect(() => {
    axios.get(`${BASE_URL}slider.php`)
      .then(response => setHomeSliderData(response.data))
      .catch(error => console.error('Error fetching slider data:', error));
  }, []);

  // إعدادات السلايدر
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'flex' , gap: '10px', color: '#fff', fontSize: '15px' }}
        onClick={onClick}
      >
        
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'flex', gap: '10px', color: '#fff', fontSize: '15px' }}
        onClick={onClick}
      >
      </div>
    );
  };
  
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    infinite: true,
    dots: true,  // تفعيل النقاط
    arrows: true,  // تفعيل الأسهم
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,  // زر التالي
    prevArrow: <PrevArrow />,  // زر السابق
  };
  

  // التعامل مع الضغط على زر الفيديو
  const handlePlayVideo = (videoLink) => {
    setVideoId(videoLink);
    setIsVideoOpen(true);
  };

  // فتح الـ modal مع محتوى معين باستخدام iframe
  const handleOpenModal = (buttonLink) => {
    setModalContent(
      <iframe
        src={buttonLink}
        title="Popup Content"
        style={{
          width: '100%',
          height: '80vh',
          border: 'none',
        }}
      />
    );
    setIsModalOpen(true);
  };

  return (
    <div className='slideH'>


<>
      {/* عرض الفيديو بناءً على رابط الفيديو من الـ API */}
      <VideoPopup videoId={videoId} />

      {/* استخدام الـ Modal كـ component شفاف */}
      <Modal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        {modalContent}
      </Modal>

      <section className="slider__area rtl  ">
        <Slider className="slider-active" {...settings}>
          {
            homeSliderData.map((slider, index) => (
              <div
                key={index}
                className={` single-slider slider__height d-flex align-items-center home_slider_${slider.id}`}
                style={{
                  backgroundImage: slider.bgType === 'image' ? `url(${BASE_URL}${slider.bgSrc})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {slider.bgType === 'video' && (
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      zIndex: -1
                    }}
                  >
                    <source src={`${BASE_URL}${slider.bgSrc}`} type="video/mp4" />
                  </video>
                )}
                <div className="slider__shape ">
                  <img className="shape triangle" src="assets/img/icon/slider/triangle.png" alt="triangle" />
                  <img className="shape dotted-square" src="assets/img/icon/slider/dotted-square.png" alt="dotted-square" />
                  <img className="shape solid-square" src="assets/img/icon/slider/solid-square.png" alt="solid-square" />
                  <img className="shape circle" src="assets/img/icon/slider/circle.png" alt="circle" />
                </div>
                <div className="container h1_slider_wrapper">
                  <div className="row sliderBox ">
                    <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10">
                      <div className="slider__content">
                        <span>{slider.subtitle || 'ترجمة افتراضية'}</span>
                        <h1>{slider.title || 'عنوان افتراضي'}</h1>
                        <p>{slider.description || 'وصف افتراضي'}</p>
                        {slider.buttonText && slider.buttonLink && (
                          <div className="slider__btn w-100">
                            <button onClick={() => handleOpenModal(slider.buttonLink)} className="z-btn z-btn-transparent">
                              {slider.buttonText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2">
                      {slider.bgType === 'video' && slider.videoLink && (
         <div className="slider__play">
         <button
           onClick={() => handlePlayVideo(slider.videoLink)}
           className="slider__play-btn"
           style={{
             zIndex: 2, // تأكد من أن زر التشغيل فوق الفيديو
             position: 'relative',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             fontSize: '24px',
             color: '#fff'
           }}
         >
           <i><FiPlay /></i>
         </button>
       </div>
       
                        
                      ) }
                    </div>
                    <footer className="footer">
  <div className="footer-content">
  <p>  إتحاد سكان بيت الوطن منصة تجمع السكان لتبادل الأفكار وبناء المستقبل. ©  <span>{new Date().getFullYear()}</span> </p>
  <div className="coming-soon">
      <h4>
        <span className="highlight">قريباً ، سيتم إطلاق مجتمع إتحاد سكان بيت الوطن، منصة تجمع السكان لتبادل الأفكار وبناء المستقبل</span>
      </h4>
    </div>
  </div>

  {/* إضافة تنسيق CSS للـ footer */}
  <style jsx>{`
  .slideH{
  overflow: hidden;
  height: 100vh;
    overflow: hidden !important;
  height: auto !important;
  min-height: 100vh   ;
  overflow: hidden  !important;
  }
    .footer {
      padding: 40px 20px;
      text-align: center;
      direction: rtl !important;
      font-family: "Beiruti", sans-serif;
    }
      .highlight {
            padding: 40px 20px;
      text-align: center;
      direction: rtl !important;
      font-family: "Beiruti", sans-serif;
      text-size:12px;
      }

    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
    }

    .coming-soon h4 {
      font-size: 22px;
      color: #ffffff;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .coming-soon .highlight {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
    }

    .footer p {
      margin: 0;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 15px;
    }

    /* التأكد من أن الخلفية شفافة تماماً */
    body, .footer {
      background: transparent;
    }
  `}</style>
  
</footer>
                  </div>


                </div>
                
                
              </div>
              
            ))
            
          }
        </Slider>
        
      </section>

      {/* إضافة الـ CSS الخاص بالخلفية لكل سلايدر بناءً على الـ API */}
      <style jsx>{`
        ${homeSliderData.map(slider => `
          .home_slider_${slider.id} {
            background-image: url(${BASE_URL}${slider.bgSrc});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }

          @media (max-width: 768px) {
            .home_slider_${slider.id} {
              background-size: contain;
              background-position: top center;
              background-repeat: no-repeat;
              height: 100vh;
            }
          }

          @media (max-width: 576px) {
            .home_slider_${slider.id} {
              background-size: contain;
              background-position: top center;
              background-repeat: no-repeat;
              height: 100vh;
            }
          }
        `)}
      `}</style>
    </>







    </div>

  );
};

export default HomeHeroArea;
