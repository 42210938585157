import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Select from 'react-select';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText } from 'react-icons/ai';
import { FaSearch, FaBuilding, FaChartBar, FaSort, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { RingLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './StatisticsPage.css';
import ApexChart from './RealtimeDashboards';

const StatisticsPage = () => {
  const [buildingStats, setBuildingStats] = useState([]);
  const [neighborhoodStats, setNeighborhoodStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [remainingBuildings, setRemainingBuildings] = useState(0);
  const [totalBuildings, setTotalBuildings] = useState(0);
  const [builtBuildings, setBuiltBuildings] = useState(0);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [filteredStats, setFilteredStats] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'build_percentage', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('أعلى');
  const [classification, setClassification] = useState('neighborhood');
  const [topN, setTopN] = useState(5);
  const [allNeighborhoods, setAllNeighborhoods] = useState(true);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [chartText, setChartText] = useState(""); // حالة لتخزين النص الخاص بالـ chart
// تعريف حالتين جديدتين لتخزين البيانات التي ستظهر في المخطط الدائري
// الحالة الخاصة بالمخطط الدائري
const [builtBuildingsForChart, setBuiltBuildingsForChart] = useState(0);
const [remainingBuildingsForChart, setRemainingBuildingsForChart] = useState(0);
const [totalBuildingsForChart, setTotalBuildingsForChart] = useState(0);

const [selectedArea, setSelectedArea] = useState(1); // المنطقة المحددة

  const [populationData, setPopulationData] = useState(0); // لتخزين عدد السكان


  // دالة لجلب عدد السكان بناءً على المنطقة أو الحي أو الحرف

  // دالة لجلب عدد السكان بناءً على المنطقة أو الحي أو الحرف
  const fetchPopulationData = async () => {
    let endpoint = '';
    let letterId = '';
    let neighborhoodId = '';

    try {
        // إذا تم اختيار الحي والحرف معًا
        if (selectedLetter && selectedNeighborhood) {
            // جلب معرف الحي بناءً على اسمه
            const neighborhoodResponse = await axios.get(`https://b-watan.net/members.php?endpoint=getNeighborhoodIdByName&neighborhood_name=${selectedNeighborhood}`);
            neighborhoodId = neighborhoodResponse.data.id;

            // جلب معرف الحرف بناءً على الحي والحرف
            const letterResponse = await axios.get(`https://b-watan.net/members.php?endpoint=getLetterIdByNeighborhoodAndLetterName&neighborhood_name=${selectedNeighborhood}&letter_name=${selectedLetter}`);
            letterId = letterResponse.data.id;

            if (letterId) {
                endpoint = `https://b-watan.net/members.php?endpoint=getPopulationStatistics&letter_id=${letterId}`;
            }
        } 
        // إذا تم اختيار الحي فقط
        else if (selectedNeighborhood) {
            const neighborhoodResponse = await axios.get(`https://b-watan.net/members.php?endpoint=getNeighborhoodIdByName&neighborhood_name=${selectedNeighborhood}`);
            console.log({neighborhoodResponse: neighborhoodResponse.data.id});
            
            neighborhoodId = neighborhoodResponse.data.id;

            if (neighborhoodId) {
                endpoint = `https://b-watan.net/members.php?endpoint=getPopulationStatistics&neighborhood_id=${neighborhoodId}`;
            }
        } 
        // إذا لم يتم تحديد الحي أو الحرف، جلب عدد سكان المنطقة بالكامل
        else if (selectedArea) {
            endpoint = `https://b-watan.net/members.php?endpoint=getPopulationStatistics&area_id=${selectedArea}`;
        }

        // جلب البيانات بناءً على endpoint النهائي
        if (endpoint) {
            const response = await axios.get(endpoint);
            if (response.data) {
              
              console.log(response.data);
              
                setPopulationData(response.data.total_population || 0);
            } else {
                console.error('No population data found');
                setPopulationData(0);
            }
        }
    } catch (error) {
        console.error('Error fetching population data:', error);
        setPopulationData(0);
    }
};

// استخدام useEffect لمراقبة التغييرات في المنطقة أو الحي أو الحرف
useEffect(() => {
    fetchPopulationData();
}, [selectedArea, selectedNeighborhood, selectedLetter]);


  // Fetch data from API
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const buildingResponse = await axios.get('https://b-watan.net/app.php?endpoint=getLetterProgress');
      const neighborhoodResponse = await axios.get('https://b-watan.net/app.php?endpoint=getNeighborhoodProgress');
      const stats = buildingResponse.data;

      const total = stats.reduce((acc, stat) => acc + parseInt(stat.total_buildings || 0, 10), 0);
      const built = stats.reduce((acc, stat) => acc + parseInt(stat.built_buildings || 0, 10), 0);

      setBuildingStats(stats);
      setNeighborhoodStats(neighborhoodResponse.data);
      setTotalBuildings(total);
      setBuiltBuildings(built);
      setRemainingBuildings(total - built);
      setLastUpdate(buildingResponse.data[0]?.last_updated || 'لا يوجد تحديث');
      if (buildingResponse.data && neighborhoodResponse.data) {
        setBuildingStats(buildingResponse.data);
        setNeighborhoodStats(neighborhoodResponse.data);
        showTop5Stats(buildingResponse.data);
        setLastUpdate(buildingResponse.data[0]?.last_updated || 'لا يوجد تحديث');
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  }, []);

  
  const showTop5Stats = (data = buildingStats) => {
    const sortedStats = [...data].sort((a, b) => b.build_percentage - a.build_percentage);
    setFilteredStats(sortedStats.slice(0, 5));
    calculateRemaining(sortedStats.slice(0, 5));
  };
  useEffect(() => {
    fetchData();
    
  }, [fetchData]);

  useEffect(() => {
    if (allNeighborhoods) {
      showTopStats(buildingStats);
      setAllNeighborhoods(true);

    } else if (selectedNeighborhood) {
      filterData(selectedNeighborhood, selectedLetter);
      setCurrentPage(1);
    } else {
      showTopStats();
      
    }
  }, [selectedNeighborhood, selectedLetter, filterType, classification, topN, allNeighborhoods]);

  const showTopStats = (data = buildingStats) => {
    let sortedStats;
    if (classification === 'neighborhood') {
      sortedStats = [...data].sort((a, b) =>
        filterType === 'أعلى' ? b.build_percentage - a.build_percentage : a.build_percentage - b.build_percentage
      );
    } else if (classification === 'letter') {
      sortedStats = [...data].sort((a, b) =>
        filterType === 'أعلى' ? b.build_percentage - a.build_percentage : a.build_percentage - b.build_percentage
      );
    }

    if (allNeighborhoods) {
      setFilteredStats(sortedStats);
    } else {
      setFilteredStats(sortedStats.slice(0, topN));
    }

    calculateRemaining(sortedStats);
  };

  const filterData = (neighborhood, letter) => {
    const filtered = buildingStats.filter(
      stat => stat.neighborhood === neighborhood && (!letter || stat.letter === letter)
    );
    const sortedFiltered = [...filtered].sort((a, b) =>
      filterType === 'أعلى' ? b.build_percentage - a.build_percentage : a.build_percentage - b.build_percentage
    );
    setFilteredStats(sortedFiltered);
    calculateRemaining(sortedFiltered);
  };

  const calculateRemaining = (data) => {
    // احسب إجمالي العمارات بناءً على البيانات القادمة من قاعدة البيانات فقط
    const total = data.reduce((acc, stat) => acc + parseInt(stat.total_buildings || 0, 10), 0);
    const built = data.reduce((acc, stat) => acc + parseInt(stat.built_buildings || 0, 10), 0);

    // تحديث إجمالي العمارات والعمارات المبنية وغير المبنية
    setTotalBuildings(total);
    setBuiltBuildings(built);
    setRemainingBuildings(total - built);

    // تأكد أن إجمالي النسبة يساوي 100%
    if (total > 0) {
        setFilteredStats(
            data.map((stat) => ({
                ...stat,
                build_percentage: ((parseInt(stat.built_buildings, 10) / parseInt(stat.total_buildings, 10)) * 100).toFixed(1),
                remaining_percentage: (100 - ((parseInt(stat.built_buildings, 10) / parseInt(stat.total_buildings, 10)) * 100)).toFixed(1),
            }))
        );
    }
};

  
  
  
  
  const handleNeighborhoodChange = (selectedOption) => {
    if (selectedOption?.value === null) {
      setAllNeighborhoods(true);
      setSelectedNeighborhood(null);
      setTopN(5);
    } else {
      setAllNeighborhoods(false);
      setSelectedNeighborhood(selectedOption ? selectedOption.value : null);
      setSelectedLetter(null);
      const neighborhoodLetters = buildingStats.filter(stat => stat.neighborhood === selectedOption.value);
      setTopN(neighborhoodLetters.length);
    }
  };

  const handleLetterChange = (selectedOption) => {
    setSelectedLetter(selectedOption ? selectedOption.value : null);
    
    if (selectedOption) {
      setIsFilterDisabled(true);
    } else {
      setIsFilterDisabled(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = buildingStats.filter(stat =>
      stat.neighborhood.toLowerCase().includes(query) ||
      stat.letter.toLowerCase().includes(query)
    );

    const sortedFiltered = [...filtered].sort((a, b) =>
      filterType === 'أعلى' ? b.build_percentage - a.build_percentage : a.build_percentage - b.build_percentage
    );
    setFilteredStats(sortedFiltered);
    calculateRemaining(sortedFiltered);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredStats].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredStats(sortedData);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    if (event.target.value === "الجميع") {
      setTopN(5);
    }
  };

  const handleTopNChange = (event) => {
    const value = Math.max(2, Math.min(Number(event.target.value), 10));
    setTopN(value);
  };

  const generateChartTitle = () => {
    if (selectedNeighborhood) {
      if (selectedLetter) {
        return `إحصائيات البناء في الحي "${selectedNeighborhood}" للحرف "${selectedLetter}"`;
      }
      return `إحصائيات البناء في الحي "${selectedNeighborhood}" لأعلى ${topN} أحرف`;
    }
    return `إحصائيات البناء لأعلى ${topN} أحرف في جميع الأحياء`;
  };

  const saveAsPDF = () => {
    const input = document.querySelector('.statistics-page-container');
    html2canvas(input, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('statistics-page.pdf');
    });
  };

  const saveAsImage = () => {
    const input = document.querySelector('.statistics-page-container');
    html2canvas(input, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'statistics-page.png';
      link.click();
    });
  };

  const exportToExcel = () => {
    const headers = ['الحي', 'الحرف', 'إجمالي العمارات', 'العمارات المبنية', 'نسبة البناء'];
    const worksheet = XLSX.utils.json_to_sheet(
      filteredStats.map(stat => ({
        'الحي': stat.neighborhood,
        'الحرف': stat.letter || 'غير محدد',
        'إجمالي العمارات': Number(stat.total_buildings).toLocaleString(),
        'العمارات المبنية': Number(stat.built_buildings).toLocaleString(),
        'نسبة البناء': stat.build_percentage + '%',
      }))
    );
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'إحصائيات');
    XLSX.writeFile(workbook, 'statistics.xlsx');
  };

  const exportToCSV = () => {
    const headers = ['الحي', 'الحرف', 'إجمالي العمارات', 'العمارات المبنية', 'نسبة البناء'];
    const worksheet = XLSX.utils.json_to_sheet(
      filteredStats.map(stat => ({
        'الحي': stat.neighborhood,
        'الحرف': stat.letter || 'غير محدد',
        'إجمالي العمارات': Number(stat.total_buildings).toLocaleString(),
        'العمارات المبنية': Number(stat.built_buildings).toLocaleString(),
        'نسبة البناء': stat.build_percentage + '%',
      }))
    );
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'statistics.csv';
    link.click();
  };

  const paginatedStats = filteredStats.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  const totalPages = Math.ceil(filteredStats.length / rowsPerPage);

  const changePage = (pageNumber) => setCurrentPage(pageNumber);

  const chartData = {
    series: [{
      name: 'إجمالي العمارات',
      data: filteredStats.slice(0, topN).map(stat => parseInt(stat.total_buildings || 0, 10))
    }, {
      name: 'العمارات المبنية',
      data: filteredStats.slice(0, topN).map(stat => parseInt(stat.built_buildings || 0, 10))
    }],
    options: {
      chart: {
        type: 'bar',
        height: 400,
        background: 'rgba(255, 255, 255, 0.2)',
        toolbar: { show: true }
      },
      colors: ['#A8DADC', '#457B9D'],
      xaxis: {
        categories: filteredStats.slice(0, topN).map(stat => stat.letter || 'غير محدد'),
        title: {
          text: 'الحرف',
          style: { color: '#fff', fontSize: '16px', fontFamily: 'Beiruti, sans-serif' }
        },
        labels: {
          style: {
            colors: '#fff',
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        title: {
          text: 'عدد العمارات',
          style: { color: '#fff', fontSize: '16px', fontFamily: 'Beiruti, sans-serif' }
        },
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
          style: {
            colors: '#fff',
            fontSize: '12px'
          }
        }
      },
      title: {
        text: generateChartTitle(),
        align: 'center',
        style: { color: '#fff', fontSize: '20px', fontFamily: 'Beiruti, sans-serif' }
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        y: {
          formatter: (val) => `${val}%`
        },
        x: {
          formatter: (val, opts) => `الحرف: ${opts.w.globals.labels[opts.dataPointIndex]}`
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const stat = filteredStats[dataPointIndex];
          return (
            `<div style="padding:10px;color:#fff;background:#333;border-radius:5px;">
              <strong>الحي: ${stat.neighborhood}</strong><br/>
              الحرف: ${stat.letter || 'غير محدد'}<br/>
              إجمالي العمارات: ${stat.total_buildings}<br/>
              العمارات المبنية: ${stat.built_buildings}<br/>
              نسبة البناء: ${stat.build_percentage}%
            </div>`
          );
        }
      },
      theme: { mode: 'dark' }
    }
  };



  
// استخدام useEffect لتحديث المخطط كلما تغيرت البيانات أو الفلاتر
useEffect(() => {
  const totalFiltered = filteredStats.reduce((acc, stat) => acc + parseInt(stat.total_buildings || 0, 10), 0);
  const builtFiltered = filteredStats.reduce((acc, stat) => acc + parseInt(stat.built_buildings || 0, 10), 0);

  setBuiltBuildingsForChart(builtFiltered);
  setRemainingBuildingsForChart(totalFiltered - builtFiltered);
  setTotalBuildingsForChart(totalFiltered);
}, [filteredStats]); // تحديث المخطط مع كل تغيير

// بيانات المخطط الدائري
const donutChartData = {
  series: [builtBuildingsForChart, remainingBuildingsForChart],
  options: {
    chart: {
      type: 'donut',
      width: 350,
      height: 350,
      background: 'transparent',
    },
    labels: ['العمارات المبنية', 'العمارات المتبقية'],
    colors: ['#FF6B6B', '#4ECDC4'],
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              color: '#fff',
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '20px',
              fontWeight: 700,
              color: '#fff',
              formatter: (val) => `${val}`, // يعرض القيمة المباشرة
            },
            total: {
              show: true,
              label: 'إجمالي العمارات',
              fontSize: '18px',
              fontWeight: 800,
              color: '#fff',
              formatter: () => `${totalBuildingsForChart}`, // حساب إجمالي العمارات المبنية والمتبقية للمخطط فقط
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        const percentage = ((opts.w.globals.series[opts.seriesIndex] / totalBuildingsForChart) * 100).toFixed(1);
        return `${percentage}%`;
      },
      style: {
        fontSize: '14px',
        fontFamily: 'Beiruti, sans-serif',
        colors: ['#fff', '#fff'],
      }
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '16px',
        fontFamily: 'Beiruti, sans-serif',
        background: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
      },
      y: {
        formatter: (val, opts) => {
          const name = opts.w.globals.labels[opts.seriesIndex];
          const count = opts.w.globals.series[opts.seriesIndex];
          const percentage = ((count / totalBuildingsForChart) * 100).toFixed(1);
          return `${name}: ${count} (${percentage}%)`;
        }
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: '#fff',
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 280,
          },
          legend: {
            position: 'bottom',
          }
        }
      }
    ]
  }
};

// عرض المخطط الدائري
<Chart
  options={donutChartData.options}
  series={donutChartData.series}
  type="donut"
  width="300"
  height="300"
/>;
  
  if (loading) {
    return (
      <div className="preloader">
        <RingLoader color="#ffffff" size={150} />
        <div className="loader-layer"></div>
      </div>
    );
  }

  const neighborhoodOptions = neighborhoodStats.map(stat => ({
    value: stat.neighborhood,
    label: stat.neighborhood
  }));

  const letterOptions = selectedNeighborhood ? [
    { value: null, label: 'جميع الأحرف' },
    ...buildingStats
      .filter(stat => stat.neighborhood === selectedNeighborhood)
      .map(stat => ({
        value: stat.letter,
        label: stat.letter
      }))
  ] : [];

  const rowsOptions = [
    { value: 5, label: '5 صفوف' },
    { value: 10, label: '10 صفوف' },
    { value: 20, label: '20 صفاً' }
  ];

  return (
    <div className="statistics-page-container">
      <div className="page-header">
        <img src="assets/img/logo/logo.png" alt="Logo" className="logo" />
        <h1 className="page-title">إحصائيات البناء</h1>
        {lastUpdate && <p className="last-update">آخر تحديث: {lastUpdate}</p>}
      <p>عدد السكان المسجلين بالإتحاد: {populationData}</p>

      </div>
      <div className="container">
        <div className="row align-items-center">
          {/* Select for Neighborhood */}
          <div className="col-12 col-md-4">
  <Select
    options={[{ value: null, label: 'جميع الأحياء' }, ...neighborhoodOptions]} // قائمة الأحياء
    onChange={handleNeighborhoodChange} // دالة تغيير الحي
    placeholder="اختر الحي" // النص الافتراضي
    className="select-box glassy w-100" // الكلاس مع التنسيق
    styles={{
      container: (base) => ({
        ...base,
        zIndex: 9999,
        backdropFilter: 'blur(10px)', // تأثير التمويه
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // خلفية شفافة
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      control: (provided) => ({
        ...provided,
        border: '1px solid #A8DADC', // لون الإطار
        backgroundColor: 'transparent', // خلفية شفافة
        color: '#fff', // لون النص
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#fff', // لون النص للقيمة المختارة
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      menu: (provided) => ({
        ...provided,
        color: '#000', // لون النص في القائمة
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.5)', // لون النص للنص الافتراضي
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(168, 218, 220, 0.5)' : 'transparent', // تغيير لون الخيار عند التركيز
        color: state.isFocused ? '#fff' : '#000', // تغيير لون النص عند التركيز
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999, // ضمان أن القائمة تظهر بشكل صحيح فوق العناصر الأخرى
      }),
    }}
    menuPortalTarget={document.body} // يضمن عرض القائمة بشكل صحيح حتى في حال تراكب العناصر الأخرى
  />
</div>


          {/* Donut Chart */}
          <div className="col-12 col-md-4 gap-2">
            <div className="donut-chart-container glassy d-flex justify-content-center align-items-center flex-column" style={{ textAlign: 'center', marginBottom: '20px' }}>
              <h3 style={{ fontFamily: 'Beiruti, sans-serif', color: '#fff', fontSize: '18px', fontWeight: 'normal', marginTop: '20px' }}>إجمالي العمارات</h3>
              <Chart
                options={donutChartData.options}
                series={donutChartData.series}
                type="donut"
                width="300"
                height="300"
              />
            </div>
          </div>

{/* Select for Letter */}
<div className="col-12 col-md-4 gap-2">
  <Select
    options={letterOptions} // قائمة الحروف
    onChange={handleLetterChange} // دالة تغيير الحرف
    placeholder="اختر الحرف" // النص الافتراضي
    className="select-box glassy w-100" // الكلاس مع التنسيق
    isDisabled={!selectedNeighborhood} // تعطيل إذا لم يتم اختيار حي
    styles={{
      container: (base) => ({
        ...base,
        zIndex: 9999, // ضمان عرض القائمة بشكل صحيح فوق العناصر الأخرى
        backdropFilter: 'blur(10px)', // تأثير التمويه للخلفية
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // خلفية شفافة
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      control: (provided) => ({
        ...provided,
        border: '1px solid #A8DADC', // لون الإطار
        backgroundColor: 'transparent', // خلفية شفافة
        color: '#fff', // لون النص
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
        opacity: !selectedNeighborhood ? 0.5 : 1, // إضافة تأثير شفافية عندما يكون الحقل معطلًا
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#fff', // لون النص للقيمة المختارة
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      menu: (provided) => ({
        ...provided,
        color: '#000', // لون النص في القائمة
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.5)', // لون النص للنص الافتراضي
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(168, 218, 220, 0.5)' : 'transparent', // لون الخلفية عند التركيز
        color: state.isFocused ? '#fff' : '#000', // تغيير لون النص عند التركيز
        fontFamily: 'Beiruti, sans-serif', // تطبيق الخط
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999, // ضمان عرض القائمة بشكل صحيح حتى مع تراكب العناصر الأخرى
      }),
    }}
    menuPortalTarget={document.body} // يضمن عرض القائمة بشكل صحيح حتى مع وجود عناصر متداخلة
  />
</div>

        </div>
      </div>

      <Tabs defaultActiveKey="stats" id="statistics-tabs" className="mb-3 justify-content-center">
        <Tab eventKey="stats" title="إحصائيات">
          <div className="filter-container d-flex justify-content-center gap-3">
            <select onChange={handleFilterTypeChange} className="filter-select glassy dark-text">
              <option className="dark-text" value="أعلى">أعلى نسبة بناء</option>
              <option className="dark-text" value="أقل">أقل نسبة بناء</option>
              <option className="dark-text" value="الجميع">الجميع</option>
            </select>

            <div className="top-n-input">
              <input
                type="number"
                placeholder="عدد الأحرف"
                value={topN}
                onChange={handleTopNChange}
                disabled={!!selectedLetter}
                className="input-text glassy"
              />
            </div>

            <select
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(Number(e.target.value))}
              className="filter-select glassy dark-text"
            >
              <option value={5}>5 صفوف</option>
              <option value={10}>10 صفوف</option>
              <option value={15}>15 صفاً</option>
              <option value={20}>20 صفاً</option>
              <option value={50}>50 صفاً</option>
              <option value={80}>80 صفاً</option>
              <option value={100}>100 صفاً</option>
            </select>
          </div>

          <div>
            {filteredStats.length > 0 && (
              <div className="chart-section">
                <Chart options={chartData.options} series={chartData.series} type="bar" height={400} />
              </div>
            )}

            {filteredStats.length === 0 && searchQuery && (
              <div className="no-data">
                <p>عفواً لا يوجد نتائج</p>
              </div>
            )}

            {filteredStats.length > 0 && (
              <table className="data-table">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('neighborhood')}>
                      <FaBuilding /> الحي {sortConfig.key === 'neighborhood' && (sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('letter')}>
                      <FaSort /> الحرف {sortConfig.key === 'letter' && (sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('total_buildings')}>
                      <FaSort /> عدد العمارات {sortConfig.key === 'total_buildings' && (sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('built_buildings')}>
                      <FaSort /> العمارات المبنية {sortConfig.key === 'built_buildings' && (sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                    <th onClick={() => handleSort('build_percentage')}>
                      <FaChartBar /> نسبة البناء {sortConfig.key === 'build_percentage' && (sortConfig.direction === 'asc' ? <FaArrowUp /> : <FaArrowDown />)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedStats.map((stat, index) => (
                    <tr key={index}>
                      <td>{stat.neighborhood}</td>
                      <td>{stat.letter || 'غير محدد'}</td>
                      <td>{parseInt(stat.total_buildings, 10)}</td>
                      <td>{parseInt(stat.built_buildings, 10)}</td>
                      <td>{stat.build_percentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {filteredStats.length > rowsPerPage && (
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => changePage(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}

            <ApexChart />
            <div className="save-icons">
              <div className="icon" onClick={saveAsPDF} title="حفظ كـ PDF">
                <AiOutlineFilePdf />
              </div>
              <div className="icon" onClick={saveAsImage} title="حفظ كـ صورة">
                <AiOutlineFileText />
              </div>
              <div className="icon" onClick={exportToExcel} title="تصدير إلى Excel">
                <AiOutlineFileExcel />
              </div>
              <div className="icon" onClick={exportToCSV} title="تصدير إلى CSV">
                <AiOutlineFileText />
              </div>
            </div>
          </div>
        </Tab>



        {/* <Tab eventKey="interactive-map" title="تحميل خريطة المنطقة " style={{ padding: '10px 15px', fontFamily: 'Beiruti, sans-serif', transition: 'color 0.3s ease', height: '250px' }}>
          <div className="filter-container d-flex justify-content-center gap-3 glassy w-100 text-white p-8 font pad">
            <h3 className="font">قريباً</h3>
          </div>
        </Tab> */}
      </Tabs>
    </div>
  );
};

export default StatisticsPage;
