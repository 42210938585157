import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import Team from "./pages/Team/Team";
import TeamDetails from "./pages/TeamDetails/TeamDetails";
import SliderManager from '../src/admin/SliderManager';
import StatisticsPage from "../src/pages/StatisticsPage/StatisticsPage";
import DescriptionPage from "./pages/StatisticsPage/description/descriptionAdmin";
import AdminPage from "./pages/StatisticsPage/admin/membersAdmin";
import MemberRegistration from "./pages/StatisticsPage/admin/members";
import HomeTwoFaq from "./pages/HomeTwo/HomeTwoFaq/HomeTwoFaq";
import AdminDashboard from "./pages/StatisticsPage/admin/membersAdmin";
import Admin from "./pages/StatisticsPage/admin/admin";



function App() {
  
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/statisticsPage" element={<StatisticsPage/>} />
            <Route path="/homeTwo" element={<HomeTwo/>} />
            <Route path="/homeThree" element={<HomeThree/>} />
            <Route path="/headerStyleFour" element={<HeaderStyleFour/>} />
            <Route path="/headerStyleFive" element={<HeaderStyleFive/>} />
            <Route path="/headerStyleSix" element={<HeaderStyleSix/>} />
            <Route path="/headerStyleSeven" element={<HeaderStyleSeven/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/servicesDetails" element={<ServicesDetails/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} />
            <Route path="/team" element={<Team/>} />
            <Route path="/teamDetails" element={<TeamDetails/>} />
            <Route path="/adminDashboard" element={<Admin/>} />
            <Route path="/memberRegistration" element={<MemberRegistration/>} />
            <Route path="/adminPage" element={<AdminPage/>} />
            <Route path="/slider" element={<SliderManager/>} />
            <Route path="/description" element={<DescriptionPage/>} />


            
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
