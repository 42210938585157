import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faExpand, faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ControlPanel.css'; // ملف CSS المعدل

const BASE_URL = 'https://b-watan.net/'; 

const ControlPanel = () => {
  const [sliders, setSliders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [slidersPerPage, setSlidersPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [editSlider, setEditSlider] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    description: '',
    bgType: 'image',
    buttonText: '',
    buttonLink: '',
    videoLink: '',
    file: null,
  });
  const [filePreview, setFilePreview] = useState(null);
  const [mediaSrc, setMediaSrc] = useState(null);

  // تحميل السلايدرات من الخادم
  useEffect(() => {
    axios.get(`${BASE_URL}slider.php`)
      .then(response => setSliders(response.data))
      .catch(error => console.error(error));
  }, []);

  // تحديث بيانات الحقول
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // تحديث ملف الصورة/الفيديو
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, file });
    setFilePreview(URL.createObjectURL(file));
  };

  // تفعيل رابط الزر فقط إذا تم إدخال نص الزر
  useEffect(() => {
    if (!formData.buttonText) {
      setFormData({ ...formData, buttonLink: '' });
    }
  }, [formData.buttonText]);

  // التعامل مع الحذف
  const handleDelete = async (id) => {
    if (sliders.length <= 1) {
      toast.error("لا يمكنك حذف آخر سلايدر.");
      return;
    }

    const confirmDelete = window.confirm("هل أنت متأكد أنك تريد حذف هذا السلايدر؟");
    if (confirmDelete) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('id', id);
        formDataToSend.append('action', 'delete');

        const response = await axios.post(`${BASE_URL}slider.php`, formDataToSend, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        if (response.data.success) {
          setSliders(sliders.filter(slider => slider.id !== id));
          toast.success("تم حذف السلايدر بنجاح");
        } else {
          toast.error(response.data.error || "حدث خطأ أثناء الحذف.");
        }
      } catch (error) {
        console.error("Error during deletion:", error);
        toast.error("حدث خطأ أثناء الحذف.");
      }
    }
  };

  // فتح نافذة التعديل مع ملء البيانات
  const handleEdit = (slider) => {
    setEditSlider(slider);
    setFormData({
      title: slider.title,
      subtitle: slider.subtitle || '',
      description: slider.description || '',
      bgType: slider.bgType || 'image',
      buttonText: slider.buttonText || '',
      buttonLink: slider.buttonLink || '',
      videoLink: slider.videoLink || '',
      file: null,
    });
    setFilePreview(null);
    setShowModal(true);
  };

  // حفظ التعديلات أو إضافة سلايدر جديد
  const handleSaveEdit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('subtitle', formData.subtitle || '');
    formDataToSend.append('description', formData.description || '');
    formDataToSend.append('bgType', formData.bgType || 'image');
    formDataToSend.append('buttonText', formData.buttonText || '');
    formDataToSend.append('buttonLink', formData.buttonLink || '');
    formDataToSend.append('videoLink', formData.videoLink || '');
    if (formData.file) {
      formDataToSend.append('file', formData.file);
    }

    if (editSlider) {
      // تعديل السلايدر
      formDataToSend.append('id', editSlider.id);
      formDataToSend.append('action', 'update');
    } else {
      // إضافة سلايدر جديد
      formDataToSend.append('action', 'create');
    }

    try {
      const response = await axios.post(`${BASE_URL}slider.php`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        if (editSlider) {
          setSliders(sliders.map(s => (s.id === editSlider.id ? { ...formData, id: editSlider.id } : s)));
          toast.success("تم تعديل السلايدر بنجاح");
        } else {
          setSliders([...sliders, response.data.slider]);
          toast.success("تم إضافة سلايدر جديد بنجاح");
        }
      } else {
        toast.error(response.data.error || "حدث خطأ أثناء الحفظ.");
      }

      setShowModal(false);
      setFilePreview(null);
      setFormData({
        title: '',
        subtitle: '',
        description: '',
        bgType: 'image',
        buttonText: '',
        buttonLink: '',
        videoLink: '',
        file: null,
      });
      setEditSlider(null);
    } catch (error) {
      toast.error("حدث خطأ أثناء الحفظ.");
    }
  };

  // إغلاق الـ Modal
  const closeModal = () => {
    setShowModal(false);
    setFormData({
      title: '',
      subtitle: '',
      description: '',
      bgType: 'image',
      buttonText: '',
      buttonLink: '',
      videoLink: '',
      file: null,
    });
    setFilePreview(null);
    setEditSlider(null);
  };

  // فتح الصورة/الفيديو في نافذة منبثقة
  const handleMediaClick = (src) => {
    setMediaSrc(src);
    setShowMediaModal(true);
  };

  // إغلاق نافذة الوسائط
  const closeMediaModal = () => setShowMediaModal(false);

  // إضافة سلايدر جديد
  const handleAddSlide = () => {
    setFormData({
      title: '',
      subtitle: '',
      description: '',
      bgType: 'image',
      buttonText: '',
      buttonLink: '',
      videoLink: '',
      file: null,
    });
    setFilePreview(null);
    setShowModal(true);
  };

  // البحث عبر جميع السلايدرات
  const filteredSliders = sliders.filter(slider =>
    slider.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    slider.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // عرض السلايدرات الحالية في الصفحة
  const displayedSliders = filteredSliders.slice(currentPage * slidersPerPage, (currentPage + 1) * slidersPerPage);

  return (
    <div className="container">
      <ToastContainer />
      <h2 className="text-center my-4">إدارة السلايدرات</h2>

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control w-75"
          placeholder="ابحث عن سلايدر..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleAddSlide}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>العنوان</th>
              <th>العنوان الفرعي</th>
              <th>الوصف</th>
              <th>الخلفية</th>
              <th>الرابط</th>
              <th>خيارات</th>
            </tr>
          </thead>
          <tbody>
            {displayedSliders.map(slider => (
              <tr key={slider.id}>
                <td>{slider.title}</td>
                <td>{slider.subtitle}</td>
                <td>{slider.description}</td>
                <td>
                  {slider.bgType === 'image' ? (
                    <img
                      src={`${BASE_URL}${slider.bgSrc}`}
                      alt="Background"
                      style={{ width: '80px', cursor: 'pointer' }}
                      onClick={() => handleMediaClick(`${BASE_URL}${slider.bgSrc}`)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faExpand}
                      onClick={() => handleMediaClick(`${BASE_URL}${slider.bgSrc}`)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </td>
                <td>
                  <button className="btn btn-link" onClick={() => window.open(`${BASE_URL}${slider.bgSrc}`, '_blank')}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </button>
                </td>
                <td>
                  <button className="btn btn-sm btn-warning mr-2" onClick={() => handleEdit(slider)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button className="btn btn-sm btn-danger" onClick={() => handleDelete(slider.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ReactPaginate
        previousLabel={'السابق'}
        nextLabel={'التالي'}
        breakLabel={'...'}
        pageCount={Math.ceil(filteredSliders.length / slidersPerPage)}
        onPageChange={({ selected }) => setCurrentPage(selected)}
        containerClassName={'pagination justify-content-center mt-4'}
        activeClassName={'active'}
      />

      <select onChange={(e) => setSlidersPerPage(Number(e.target.value))} className="form-select mt-3">
        <option value={5}>5 سلايدرات</option>
        <option value={10}>10 سلايدرات</option>
        <option value={15}>15 سلايدرات</option>
        <option value={20}>20 سلايدرات</option>
      </select>

      {/* Modal for Editing or Adding */}
      {showModal && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-dialog modal-xl modal-centered" onClick={(e) => e.stopPropagation()} style={{ maxWidth: '95vw', width: '100%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{editSlider ? 'تعديل السلايدر' : 'إضافة سلايدر جديد'}</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>العنوان</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><FontAwesomeIcon icon={faEdit} /></span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>العنوان الفرعي (اختياري)</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><FontAwesomeIcon icon={faEdit} /></span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="subtitle"
                          value={formData.subtitle}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>الوصف</label>
                      <textarea
                        className="form-control"
                        name="description"
                        rows="3"
                        value={formData.description}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label>نوع الخلفية</label>
                      <select
                        className="form-control"
                        name="bgType"
                        value={formData.bgType}
                        onChange={handleInputChange}
                      >
                        <option value="image">صورة</option>
                        <option value="video">فيديو</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>اختر {formData.bgType === 'image' ? 'صورة' : 'فيديو'}</label>
                      <input
                        type="file"
                        className="form-control"
                        accept={formData.bgType === 'image' ? 'image/*' : 'video/*'}
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>نص الزر (اختياري)</label>
                      <input
                        type="text"
                        className="form-control"
                        name="buttonText"
                        value={formData.buttonText}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>رابط الزر (اختياري)</label>
                      <input
                        type="text"
                        className="form-control"
                        name="buttonLink"
                        value={formData.buttonLink}
                        onChange={handleInputChange}
                        disabled={!formData.buttonText} 
                      />
                    </div>
                    <div className="form-group">
                      <label>رابط الفيديو (اختياري)</label>
                      <input
                        type="text"
                        className="form-control"
                        name="videoLink"
                        value={formData.videoLink}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {filePreview && (
                      <div className="mt-3">
                        {formData.bgType === 'image' ? (
                          <img
                            src={filePreview}
                            alt="Preview"
                            style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                          />
                        ) : (
                          <video controls style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}>
                            <source src={filePreview} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={closeModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <button className="btn btn-primary" onClick={handleSaveEdit}>
                  <FontAwesomeIcon icon={editSlider ? faEdit : faPlus} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Media Preview */}
      {showMediaModal && (
        <div className="modal-backdrop" onClick={closeMediaModal}>
          <div className="modal-dialog modal-lg modal-centered" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-body">
                {mediaSrc && mediaSrc.includes('.mp4') ? (
                  <video controls style={{ width: '100%', maxHeight: '500px' }}>
                    <source src={mediaSrc} type="video/mp4" />
                  </video>
                ) : (
                  <img src={mediaSrc} alt="Media" style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }} />
                )}
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={closeMediaModal}>إغلاق</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlPanel;
